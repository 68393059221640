import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTransition, animated } from 'react-spring';
import useTranslate from 'hooks/useTranslate';
import Button from 'atoms/Button';
import Box from 'molecules/Box';

const Modal = (props) => {
    const t = useTranslate();
    const transitions = useTransition(props.isOpen, {
        from: { opacity: 0, y: 64 },
        enter: { opacity: 1, y: 0 },
        leave: { opacity: 0, y: 64 },
    });

    return transitions((styles, isOpen) => (
        isOpen && (
            <animated.div
                className={classnames(
                    'ui-modal',
                    {
                        'ui-modal--loading': props.loading,
                        'ui-modal--alert': props.isAlert,
                    }
                )}
                style={{ opacity: styles.opacity }}
            >
                <div
                    className="ui-modal__background"
                    onClick={props.close}
                />

                {props.confirm && (
                    <div
                        className="ui-modal__close"
                        onClick={props.close}
                    >
                        <i className="fas fa-times" />
                    </div>
                )}

                <animated.div style={{ y: styles.y }}>
                    <Box width={props.isAlert ? 400 : 800}>
                        <div className="ui-modal__body">
                            {props.headline && (
                                <div className="ui-modal__headline">{props.headline}</div>
                            )}

                            {props.children}
                        </div>

                        <div className="ui-modal__footer">
                            <Button
                                disabled={props.disabled}
                                label={props.confirmLabel || t('modal.button.okay')}
                                loading={props.loading}
                                onClick={props.confirm || props.close}
                            />
                        </div>
                    </Box>
                </animated.div>
            </animated.div>
        )
    ));
};

Modal.defaultProps = {
    confirm: null,
    confirmLabel: null,
    disabled: false,
    headline: null,
    isAlert: false,
    loading: false,
    width: 800,
};

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    close: PropTypes.func.isRequired,
    confirm: PropTypes.func,
    confirmLabel: PropTypes.string,
    disabled: PropTypes.bool,
    headline: PropTypes.node,
    isAlert: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    width: PropTypes.number,
};

export default Modal;
