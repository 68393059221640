import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTransition, animated } from 'react-spring';
import useTranslate from 'hooks/useTranslate';
import assets from 'shared/assets';
import options from 'shared/options';
import Button from 'atoms/Button';
import ColorPicker from 'atoms/ColorPicker';
import TextField from 'atoms/TextField';
import Language from 'atoms/Language';
import CopyableLink from 'atoms/CopyableLink';
import ViewButton from 'atoms/ViewButton';
import Form, { FormItem } from 'molecules/Form';

const Player = (props) => {
    const t = useTranslate();
    const [name, setName] = useState('');
    const [color, setColor] = useState(null);
    const transitions = useTransition(!!props.player, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        trail: 100,
    });

    const joinGame = () => {
        props.joinGame(name, color);
    };

    const renderContent = (hasPlayer) => {
        if (hasPlayer) {
            return (
                <div className="lobby-player__wait">
                    <div className="lobby-player__wait-text">
                        <div>{t('lobby.welcome')}</div>

                        {t('lobby.welcome.sub')}
                    </div>

                    <div className="lobby-player__wait-options">
                        <Form>
                            <FormItem label={t('lobby.link')}>
                                <CopyableLink>{window.location.href}</CopyableLink>
                            </FormItem>

                            <FormItem label={t('lobby.language')}>
                                <Language
                                    language={props.game.language}
                                    selected
                                />
                            </FormItem>

                            {Object.entries(options).map(([key, option]) => (
                                <FormItem
                                    key={key}
                                    label={t(option.label)}
                                >
                                    <div className="lobby-player__wait-value">
                                        {option.type === 'number' && (
                                            props.game.options[key]
                                        )}

                                        {option.type === 'checkbox' && (
                                            options[key].values.map(({ label, value }) => (
                                                <div
                                                    key={label}
                                                    className={classnames(
                                                        'lobby-player__wait-check',
                                                        { 'lobby-player__wait-check--checked': props.game.options[key].includes(value) }
                                                    )}
                                                >
                                                    <div>
                                                        <i className="fas fa-check" />

                                                        {t(label)}

                                                        <span className="lobby__category-count">
                                                            ({assets.categories[props.game.language][value].length} Kategorien)
                                                        </span>
                                                    </div>

                                                    <ViewButton onClick={props.openCardSetHandler(value)} />
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </FormItem>
                            ))}
                        </Form>
                    </div>
                </div>
            );
        }

        return (
            <div className="lobby-player__connect">
                <Form>
                    <FormItem label={t('main.form.name')}>
                        <TextField
                            maxLength={24}
                            onChange={setName}
                            value={name}
                        />
                    </FormItem>

                    <FormItem label={t('main.form.color')}>
                        <ColorPicker
                            blockedColors={props.game.players.map((player) => player.color)}
                            onChange={setColor}
                            value={color}
                        />
                    </FormItem>
                </Form>

                {props.game.players.length <= 10 && (
                    <Button
                        disabled={
                            !name.trim()
                            || !color || props.game.players.map((player) => player.name).includes(name)
                            || !!props.player
                        }
                        label={t('lobby.button.join')}
                        onClick={joinGame}
                    />
                )}
            </div>
        );
    };

    return transitions((styles, hasPlayer) => (
        <animated.div
            className="lobby-player"
            style={styles}
        >
            {renderContent(hasPlayer)}
        </animated.div>
    ));
};

Player.defaultProps = {
    player: null,
};

Player.propTypes = {
    game: PropTypes.object.isRequired,
    joinGame: PropTypes.func.isRequired,
    openCardSetHandler: PropTypes.func.isRequired,
    player: PropTypes.object,
};

export default Player;
