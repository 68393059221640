import Action from '../classes/Action';
import states from '../states';
import assets from '../assets';

export default class extends Action {
    static get id() {
        return 0;
    }

    static get isSetupAction() {
        return true;
    }

    static perform({ allPlayers, options, language }) {
        return {
            game: {
                round: 0,
                categories: options.cardSets.reduce((result, cardSet) => [
                    ...result,
                    ...assets.categories[language][cardSet],
                ], []),
            },
            players: allPlayers.map((player) => ({
                ...player,
                score: 0,
                previousScore: 0,
                tiles: [],
                category: null,
            })),
            state: states.ROUND_PREPARATION.id,
        };
    }
}
