import { useState } from 'react';
import { useSpringRef, useSprings, useChain } from 'react-spring';

export default (players, categories, scale) => {
    const [animationDone, setAnimationDone] = useState(false);
    const playerSpringRef = useSpringRef();
    const playerSpring = useSprings(players.length, players.map((player, index) => ({
        ref: playerSpringRef,
        delay: index * 50,
        from: {
            y: -100 / scale,
        },
        to: {
            y: 0,
        },
    })));
    const categorySpringRef = useSpringRef();
    const categorySpring = useSprings(categories.length, categories.map((category, index) => ({
        ref: categorySpringRef,
        delay: index * 50,
        from: {
            y: 100 / scale,
        },
        to: {
            y: 0,
        },
        onRest: () => {
            setAnimationDone(true);
        },
    })));

    useChain([playerSpringRef, categorySpringRef], [0, 0.5]);

    return {
        anim: {
            players: playerSpring,
            categories: categorySpring,
        },
        done: animationDone,
    };
};
