import React from 'react';
import PropTypes from 'prop-types';
import states from 'shared/states';
import useTranslate from 'hooks/useTranslate';
import Button from 'atoms/Button';
import PlayerName from 'atoms/PlayerName';

const Footer = (props) => {
    const t = useTranslate();

    const isReady = () => {
        if (props.state.state === states.WORD_BUILDING.id) {
            return props.player.tiles.word.length > 0;
        }

        if (props.state.state === states.GUESSING.id) {
            return Object.keys(props.player.guesses).length > 0;
        }

        return false;
    };

    const renderPendingPlayers = () => {
        const items = props.pendingPlayers.map((player, index) => (
            <React.Fragment key={player.id}>
                {index !== 0 && (
                    index === props.pendingPlayers.length - 1 ? (
                        <>&nbsp;{t('live.footer.message.watiting.and')}&nbsp;</>
                    ) : (
                        <>,&nbsp;</>
                    )
                )}

                <PlayerName
                    color={player.color}
                    name={player.name}
                />
            </React.Fragment>
        ));

        return items;
    };

    const renderContent = () => {
        const { currentPlayer, isAllowed, handle } = props.submit;

        if (!props.player || props.state.state === states.GAME_END.id) {
            return null;
        }

        if (currentPlayer && currentPlayer.id !== props.player.id) {
            return (
                <>
                    {t('live.footer.message.scoring', { player: (
                        <PlayerName
                            color={currentPlayer.color}
                            name={currentPlayer.name}
                        />
                    ) })}
                </>
            );
        }

        if (isReady()) {
            return (
                t('live.footer.message.waiting', { players: renderPendingPlayers() })
            );
        }

        return (
            <Button
                disabled={!isAllowed}
                label={t('live.footer.button.done')}
                onClick={handle}
            />
        );
    };

    return (
        <div className="live-footer">
            {renderContent()}
        </div>
    );
};

Footer.defaultProps = {
    player: null,
};

Footer.propTypes = {
    player: PropTypes.object,
    pendingPlayers: PropTypes.array.isRequired,
    state: PropTypes.object.isRequired,
    submit: PropTypes.shape({
        handle: PropTypes.func.isRequired,
        isAllowed: PropTypes.bool.isRequired,
        currentPlayer: PropTypes.object,
    }).isRequired,
};

export default Footer;
