import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const IconButton = (props) => (
    <div
        className={classnames(
            'ui-icon-button',
            { 'ui-icon-button--highlighted': props.highlighted },
        )}
        onClick={props.onClick}
    >
        <span>
            <i
                className={classnames(
                    'fas',
                    `fa-${props.icon}`
                )}
            />
        </span>

        {props.label}
    </div>
);

IconButton.defaultProps = {
    highlighted: false,
};

IconButton.propTypes = {
    highlighted: PropTypes.bool,
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default IconButton;
