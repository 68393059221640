export const PLAYER = `
    id
    name
    color
    isAdmin
    connected
    game {
        id
    }
`;
