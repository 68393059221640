import Action from '../classes/Action';
import states from '../states';

export default class extends Action {
    static get id() {
        return 6;
    }

    static get isServerAction() {
        return true;
    }

    static get isEndGameAction() {
        return true;
    }

    static isValid({ state }) {
        return state.state === states.GAME_END.id;
    }
}
