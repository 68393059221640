export default {
    categories: {
        de: {
            family: [
                '"Feuer" in der Sprache der Neandertaler',
                'Abstraktes Brettspiel',
                'Amerikanischer Großkonzern',
                'Anderes Wort für "arm"',
                'Anderes Wort für "Faulpelz"',
                'Anderes Wort für "Fleischereifachverkäuerin"',
                'Anderes Wort für "Furz"',
                'Anderes Wort für "Gemeckere"',
                'Anderes Wort für "Gülle"',
                'Anderes Wort für "Klobürste"',
                'Anderes Wort für "klug"',
                'Anderes Wort für "Nasenpopel"',
                'Anderes Wort für "Quatsch"',
                'Anderes Wort für "Rülpser"',
                'Anderes Wort für "Teufel"',
                'Australisches Beuteltier',
                'Außerirdisches Volk',
                'Ägyptischer Pharao',
                'Babynahrung',
                'Begriff aus der Jägersprache',
                'Berg in den Dolomiten',
                'Bezeichnung für einen miesen Lehrer',
                'Boy-Band',
                'Charakter aus "Alice im Wunderland"',
                'Charakter aus "Star Wars"',
                'Chinesische Großstadt',
                'Comicfigur',
                'Computerspiel',
                'Das ideale Weihnachtsgeschenk',
                'Donald Ducks vierter Neffe',
                'Dorf in Oberbayern',
                'Edelsteinsorte',
                'Eintopf-Zutat',
                'Eis-Sorte',
                'Energydrink',
                'Etwas Aufblasbares',
                'Etwas aus Glas',
                'Etwas aus Gummi',
                'Etwas riesig Großes',
                'Etwas Rundes',
                'Etwas sehr Teures',
                'Etwas Spitzes',
                'Etwas typisch Schwarzes',
                'Etwas Ungesundes',
                'Etwas winzig Kleines',
                'Etwas, das ewig hält',
                'Etwas, das Skandinavier besonders mögen',
                'Fernsehzeitung',
                'Figur aus "Harry Potter"',
                'Fluss in Alaska',
                'Französischer Weichkäse',
                'Frauenzeitschrift',
                'Gefährliches Monster',
                'Gefürchteter Pirat',
                'Gerät im Fitness-Studio',
                'Germanische Gottheit',
                'Getreidesorte',
                'Giftiger Pilz',
                'Hat gewaltigen Hunger',
                'Hat einen Motor',
                'Hat Knöpfe',
                'Hat Löcher',
                'Hat null Bock',
                'Hat Widerhaken',
                'Hauptstadt des Pluto',
                'Hundefutter',
                'Ist besonders blöd',
                'Ist besonders schlau',
                'Ist giftig und unsichtbar',
                'Ist groß und gefährlich',
                'Ist hart wie ein Diamant',
                'Ist lang und dünn',
                'Italienisches Schimpfwort',
                'Japanische Insel',
                'Jugendwort für "doof"',
                'Kann sich tot stellen',
                'Katzenrasse',
                'Kinderspielzeug',
                'Kleines Nagetier',
                'Kleines Stadtauto',
                'Kuscheltier',
                'Künstliches Süßungsmittel',
                'Kreuzfahrtschiff',
                'Label für Mädchen-Mode',
                'Landwirtschaftliches Gerät',
                'Lustiges Partyspiel',
                'Macht gesund',
                'Macht gute Laune',
                'Macht Krach',
                'Macht krank',
                'Macht schlechte Laune',
                'Macht wütend',
                'Marke für Babykleidung',
                'Marke für Babywindeln',
                'Militärischer Befehl',
                'Mittelalterliches Folterinstrument',
                'Mixtur aus Orangensaft und Lebertran',
                'Moderner Musikstil',
                'Müsli-Zutat',
                'Nachfolgewährung des Euro',
                'Name eines Drachen',
                'Name für die Zahnfee',
                'Name von Tarzans Lieblingsaffen',
                'Neu entdeckter Planet',
                'Neuartiges Baumaterial',
                'Neuartiges Flugzeug',
                'Nordamerikanischer Indianerstamm',
                'Physikalische Maßeinheit',
                'Pizza-Sorte',
                'Planet',
                'Puddingsorte',
                'Putzmittel',
                'Römischer Kaiser',
                'Rufname eines Kampfhundes',
                'Salzige Knabberei',
                'Schmetterlingsart',
                'Schminkutensil',
                'Schneewittchens achter Zwerg',
                'Schokoriegel',
                'Schuhmarke',
                'Schützt vor Unfällen',
                'Seltene Gesteinsart',
                'Seltene Krankheit',
                'Shampoo',
                'Singvogel',
                'Skiort in den Alpen',
                'Spanischer Maler',
                'Spitzname für Justin Bieber',
                'Stachelige Wüstenpflanze',
                'Stamm in Zentralafrika',
                'Steinzeitliches Werkzeug',
                'Superheld',
                'Südamerikanisches Insekt',
                'Südlichster Ort der Erde',
                'Tanzstil',
                'Tee-Sorte',
                'Teil eines Smartphones',
                'Tiefseebewohner',
                'Tierlaut',
                'Titel eines Actionfilms',
                'Toilettenpapier-Marke',
                'Trendsportart',
                'Tropische Frucht',
                'TV-Quiz',
                'Ungeliebtes Schulfach',
                'Ungeziefer',
                'Ungezogenes Kind',
                'Vegetarischer Burger',
                'Volksmusikgruppe aus dem Zillertal',
                'Wahnsinn! Ein/e...',
                'Warme Winterbekleidung',
                'Wie niedlich! Ein/e...',
                'Wohlschmeckendes Gewürz',
                'Zahnpasta',
            ],
            adult: [
                '"Heiß!" auf Pakistanisch',
                '"Prost!" auf Bulgarisch',
                '"Schnee" in der Eskimo-Sprache',
                'Abfällige Bezeichnung für ein altes Auto',
                'Abfälliger Ausdruck für "Banker"',
                'Abführmittel',
                'Anderes Wort für "Blasenschwäche"',
                'Anderes Wort für "Brustschwimmen"',
                'Anderes Wort für "Brustwarze"',
                'Anderes Wort für "Donau-schifffahrtskapitän"',
                'Anderes Wort für "erektile Dysfunktion"',
                'Anderes Wort für "Fußpilz"',
                'Anderes Wort für "Kannibale"',
                'Anderes Wort für "Kunstkritiker"',
                'Anderes Wort für "Legasthenie"',
                'Anderes Wort für "Massagestab"',
                'Anderes Wort für "Migräne"',
                'Anderes Wort für "Morgenlatte"',
                'Anderes Wort für "Nierensteinentfernung"',
                'Anderes Wort für "Oralverkehr"',
                'Anderes Wort für "Rotznase"',
                'Anderes Wort für "Scheidenkrampf"',
                'Anderes Wort für "sein bestes Stück"',
                'Anderes Wort für "Sexshop"',
                'Anderes Wort für "Techtelmechtel"',
                'Anderes Wort für "Unterleib"',
                'Anderes Wort für "Übergewicht"',
                'Anderes Wort für "Verkehr"',
                'Anderes Wort für "Weltuntergang"',
                'Anderes Wort für "Wespentaille"',
                'Anderes Wort für "Zungenkuss"',
                'Antidepressivum',
                'Atemwegserkrankung',
                'Befehl beim Hundeschlittenrennen',
                'Begriff aus der Seemannssprache',
                'Beinhaltet einen Schaltkreis',
                'Beliebter Zeitvertreib',
                'Beschäftigte/r einer Recycling-Firma',
                'Bestandteil eines Raumschiffs',
                'Brasilianische Provinz',
                'Chinesisches Wort für "Fahrrad"',
                'Das achte antike Weltwunder',
                'Davor hat mich meine Oma immer gewarnt',
                'Die elfte Disziplin beim Zehnkampf',
                'Edles Parfüm',
                'Etwas aus Gold',
                'Etwas aus Latex',
                'Etwas Dünnflüssiges',
                'Etwas Glitschiges',
                'Etwas ohne Gehirn',
                'Etwas sehr Hohes',
                'Etwas sehr Leichtes',
                'Etwas Stinkendes',
                'Etwas typisch Weißes',
                'Etwas ungemein Schweres',
                'Etwas Übelkeit Erregendes',
                'Etwas zum Streicheln',
                'Etwas, das Südländer mögen',
                'Figur aus "Herr der Ringe"',
                'Finnischer Skisportler',
                'Flirt-App',
                'Französische Weinsorte',
                'Führer durch den Behördendschungel',
                'Gefährliche Spinnenart',
                'Geisteskrankheit',
                'Geschlechtskrankheit',
                'Gerät beim Urologen',
                'Geschieht im Eifer des Gefechts',
                'Geschieht verbotener-weise im Kloster',
                'Geschieht vor allem im Bett',
                'Gestalt aus der griechischen Mythologie',
                'Hawaiianischer Liebesbrauch',
                'Heavy-Metal-Band',
                'Heißeste Stelle auf der Erde',
                'Hochprozentiger Cocktail',
                'Höchster Ort der Erde',
                'Indiostamm am Amazonas',
                'Indische Göttin',
                'Indisches Hauptgericht',
                'Indonesisches Wort für "Reis"',
                'Isländisch für "Ruhe!"',
                'Ist blond',
                'Ist klein und schmutzig',
                'Ist kurz und dick',
                'Ist mal heiß und mal kalt',
                'Ist mal klein und mal groß',
                'Ist vor allem nachts aktiv',
                'Italienischer Liebesschwur',
                'Jamaikanische Rauchware',
                'James-Bond-Bösewicht',
                'Kaffeesorte aus Mittelamerika',
                'Klassischer Komponist',
                'Kneipe auf der Reeperbahn',
                'Kondom-Marke',
                'Kosename für den/die Liebste/n',
                'Kosewort für "großen Busen"',
                'König von Thailand',
                'Künstlername einer Domina',
                'Künstlername einer Erotikdarstellerin',
                'Künstlername eines Erotikdarstellers',
                'Lässt den Papst erröten',
                'Lateinischer Name für den Gesäßmuskel',
                'Lindert Kopfschmerzen',
                'Luxushotel',
                'Macht Flecken',
                'Macht Flecken weg',
                'Macht Kopfschmerzen',
                'Maskottchen eines Boxers',
                'Männermagazin',
                'Mittel gegen Durchfall',
                'Mittel gegen Lustlosigkeit',
                'Mixtur aus sauren Gurken und Erdbeermarmelade',
                'Mondkrater',
                'Name einer Motorrad-Gang',
                'Natürliches Verhütungsmittel',
                'Norwegisches Fischgericht',
                'Outdoor-Marke',
                'Österreichische Mehlspeise',
                'Persischer Herrscher',
                'Polnischer Kraftausdruck',
                'Programmiersprache',
                'Ratgeber für Esoteriker',
                'Ratgeber für Masochisten',
                'Ratschlag für Misanthropen',
                'Scharfes ungarisches Fleischgericht',
                'Seltene Tierart auf den Galapagos-Inseln',
                'Sexspielzeug',
                'Sexy Damenunterwäsche',
                'Sibirischer Ort',
                'Soeben entdecktes Bakterium',
                'Sonderbarer Platz für Sex',
                'Sportart für Zombies',
                'Steht dem Schneemann gut',
                'Stellung aus dem Kamasutra',
                'Südafrikanisches Gemüse',
                'Südsee-Insel',
                'Synthetische Partydroge',
                'Tipp für Veganer',
                'Titel eines Erotikfilms',
                'Tunesisches Nationalgericht',
                'Türkische Vorspeise',
                'Ungewöhnliches Mordwerkzeug',
                'Vietnamesische Fruchtbarkeitsgöttin',
                'Vorsicht! Ein/e...',
                'Vulkan auf einer Karibikinsel',
                'Was ein Politiker heimlich treibt',
                'Was eine Frau glücklich macht',
                'Was einen Mann glücklich macht',
                'WC-Reiniger',
                'Westernheld',
                'Wird gern schönheits-chirurgisch korrigiert',
                'Zu viel Alkohol macht...',
                'Zwielichtige Gestalt',
            ],
            online: [
                '"Nein!" auf Swahili',
                'Anderes Wort für "verrückt"',
                'Antikes Möbelstück',
                'Asiatischer Zwergstaat',
                'Beliebter Jungenname',
                'Beliebter Mädchenname',
                'Deutscher Schriftsteller',
                'Disney-Figur',
                'Ein furchtbares Verbrechen',
                'Etwas flauschig Weiches',
                'Etwas Quadratisches',
                'Etwas typisch Rotes',
                'Geräusch, das man beim Niesen macht',
                'Geräusch, das man beim Sterben macht',
                'Gibt es in Dosen',
                'Haha! Ein/e...',
                'Handyhersteller',
                'Hat lange Haare',
                'Hollywood-Schauspieler',
                'Horrorroman',
                'Hustensaft',
                'Inneres Organ',
                'Jugendwort des Jahres',
                'Kann man reiten',
                'Kompliment',
                'Name eines Pokémon',
                'Name eines Wrestlers',
                'Populäres Hashtag',
                'Produkt in einem schwedischen Möbelhaus',
                'Schicke Kopfbedeckung',
                'Schusswaffe',
                'Social-Media-Plattform',
                'Verloren geglaubtes Grimmsches Märchen',
                'Wanderzirkus',
                'Wird gerne gesammelt',
                'Wohlriechende Blume',
                'Würzsauce',
            ],
        },
        en: {
            family: [
                '"Fire" in Neanderthal language',
                'Abstract board game',
                'Agricultural equipment',
                'Alien tribe',
                'American corporation',
                'Animal sound',
                'Artificial sweetener',
                'Attack dog\'s name',
                'Australian marsupial',
                'Awesome! It\'s a...',
                'Baby food',
                'Babywear brand',
                'Boy band',
                'Butterfly species',
                'Can play dead',
                'Can\'t be bothered to do anything',
                'Capital city of Pluto',
                'Cartoon character',
                'Cat breed',
                'Central African tribe',
                'Cereal ingredient',
                'Character from "Alice in Wonderland"',
                'Character from "Harry Potter"',
                'Character from "Star Wars"',
                'Children\'s toy',
                'Chinese metropolis',
                'Chocolate bar',
                'Cleaning agent',
                'Computer game',
                'Cruise ship',
                'Currency succeeding the Euro',
                'Dance style',
                'Dangerous monster',
                'Deep-sea dweller',
                'Diaper brand',
                'Dog food',
                'Donald Duck\'s fourth nephew',
                'Egyptian pharao',
                'Energy drink',
                'Folk musicians from the Ziller valley',
                'French soft cheese',
                'Fun party game',
                'Gemstone',
                'Germanic deity',
                'Gym equipment',
                'Has a motor',
                'Has barbed hooks',
                'Has buttons',
                'Has holes',
                'How cute! It\'s a...',
                'Hunting jargon',
                'Ice cream flavour',
                'Infamous pirate',
                'Innovative construction material',
                'Is extremely hungry',
                'Is as hard as a diamond',
                'Is large and dangerous',
                'Is long and thin',
                'Is particularly stupid',
                'Is particularly clever',
                'Is venomous and invisible',
                'Italian cuss word',
                'Japanese island',
                'Label for girls\' fashion',
                'Make-up accessory',
                'Makes you angry',
                'Makes you healthy',
                'Makes noise',
                'Makes you sick',
                'Medieval torture device',
                'Military command',
                'Mix of orange juice and cod liver oil',
                'Modern music genre',
                'Mountain in the Dolomites',
                'Name of a dragon',
                'Name of Tarzan\'s favourite monkey',
                'Name of the tooth fairy',
                'Native American tribe',
                'Naughty child',
                'New kind of plane',
                'Newly discovered planet',
                'Nickname for Justin Bieber',
                'Other word for "booger"',
                'Other word for "burp"',
                'Other word for "butchery sales lady"',
                'Other word for "devil"',
                'Other word for "fart"',
                'Other word for "manure"',
                'Other word for "nagging"',
                'Other word for "nonsense"',
                'Other word for "poor"',
                'Other word for "slacker"',
                'Other word for "smart"',
                'Other word for "toilet brush"',
                'Part of a smartphone',
                'Physical unit',
                'Planet',
                'Poisonous mushroom',
                'Prevents accidents',
                'Prickly desert plant',
                'Puts you in a bad mood',
                'Puts you in a good mood',
                'Rare disease',
                'Rare mineral',
                'River in Alaska',
                'Roman emperor',
                'Salty snack',
                'Savoury spice',
                'Shampoo',
                'Shoe brand',
                'Ski resort in the Alps',
                'Small city car',
                'Small rodent',
                'Snow White\'s eighth dwarf',
                'Songbird',
                'Something gigantic',
                'Something inflatable',
                'Something made of glass',
                'Something made of rubber',
                'Something pointy',
                'Something round',
                'Something Scandinavians enjoy a lot',
                'Something that last forever',
                'Something tiny',
                'Something typically black',
                'Something unhealthy',
                'Something very expensive',
                'South American insect',
                'Southernmost place on Earth',
                'Spanish painter',
                'Stew ingredient',
                'Stuffed animal',
                'Superhero',
                'Term for a lousy teacher',
                'The ideal Christmas present',
                'Title of an action movie',
                'Toilet paper brand',
                'Tool from the Stone Age',
                'Tooth paste',
                'Trend sport',
                'Tropical fruit',
                'TV magazine',
                'TV quiz',
                'Type of grain',
                'Type of pizza',
                'Type of pudding',
                'Type of tea',
                'Unpopular school subject',
                'Vegetarian burger',
                'Vermin',
                'Village in Upper Bavaria',
                'Warm winter clothing',
                'Women\'s magazine',
                'Youth slang for "dumb"',
            ],
            adult: [
                '"Cheers!" in Bulgarian',
                '"Hot!" in Pakistani',
                '"Snow" in Eskimo language',
                'A boxer\'s maskot',
                'Actor in an erotic movie',
                'Actress in an erotic movie',
                'Advice for misanthropes',
                'Advice for vegans',
                'Antidepressant',
                'Austrian pastry',
                'Brazilian province',
                'Causes a headache',
                'Causes stains',
                'Character from "Lord of the Rings"',
                'Character in Greek mythology',
                'Chinese word for "bicycle"',
                'Classical composer',
                'Command at a dog sled race',
                'Condom brand',
                'Contains circuitry',
                'Dangerous species of spider',
                'Derogatory term for "banker"',
                'Derogatory term for an old car',
                'Employee at a recycling factory',
                'Finnish skier',
                'Flirt app',
                'French type of wine',
                'Guide through the bureaucratic maze',
                'Guidebook for esoterics',
                'Guidebook for masochists',
                'Happens in the heat of the moment',
                'Hawaiian love custom',
                'Heavy Metal band',
                'Hero in a Western',
                'Highball cocktail',
                'Highest place on Earth',
                'Hottest place on Earth',
                'Icelandic for "Silence!"',
                'Illegal happenings at the monastery',
                'Indian goddess',
                'Indian main dish',
                'Indigenous people at the Amazon',
                'Indonesian word for "rice"',
                'Is blonde',
                'Is often corrected with plastic surgery',
                'Is predominantly nocturnal',
                'Is sometimes hot and sometimes cold',
                'Is sometimes little and sometimes big',
                'Is short and thick',
                'Is small and dirty',
                'Italian oath of love',
                'Jamaican smoked goods',
                'James Bond villain',
                'King of Thailand',
                'Latin name for glutes',
                'Laxative',
                'Looks dapper on a snowman',
                'Lunar crater',
                'Luxury hotel',
                'Makes a man happy',
                'Makes a woman happy',
                'Makes the Pope blush',
                'Men\'s magazine',
                'Mental illness',
                'Mix of pickled cucumbers and strawberry jam',
                'Name of a biker gang',
                'Natural contraceptive',
                'Nautical jargon',
                'Newly discovered bacterium',
                'Noble perfume',
                'Norwegian fish dish',
                'Other word for "art critic"',
                'Other word for "athlete\'s foot"',
                'Other word for "bladder weakness"',
                'Other word for "breaststroke"',
                'Other word for "cannibal"',
                'Other word for "crotch"',
                'Other word for "Danube shipping captain"',
                'Other word for "doomsday"',
                'Other word for "dyslexia"',
                'Other word for "erectile dysfunction"',
                'Other word for "French kiss"',
                'Other word for "hanky-panky"',
                'Other word for "his junk"',
                'Other word for "intercourse"',
                'Other word for "kidney stone removal"',
                'Other word for "migraine"',
                'Other word for "morning wood"',
                'Other word for "nipple"',
                'Other word for "oral intercourse"',
                'Other word for "overweight"',
                'Other word for "sex store"',
                'Other word for "snotnose"',
                'Other word for "vaginal spasm"',
                'Other word for "vibrator"',
                'Other word for "wasp waist"',
                'Outdoor label',
                'Persian monarch',
                'Pet name for "big boobs"',
                'Pet name for your sweetheart',
                'Polish cuss word',
                'Popular time killer',
                'Position in the Kamasutra',
                'Primarily happens in bed',
                'Programming language',
                'Pub in the red-light district',
                'Rare animal on the Galapagos Islands',
                'Relieves a headache',
                'Remedy for diarrhea',
                'Remedy for listlessness',
                'Removes stains',
                'Respiratory disease',
                'Sex toy',
                'Sexually transmitted disease',
                'Sexy lingerie',
                'Sketchy figure',
                'Someplace in Siberia',
                'Something a Southern European enjoys',
                'Something extremely heavy',
                'Something made of gold',
                'Something made of latex',
                'Something nauseating',
                'Something slippery',
                'Something smelly',
                'Something strokable',
                'Something typically white',
                'Something very high',
                'Something very light',
                'Something with low viscosity',
                'Something without a brain',
                'South African vegetable',
                'South Sea island',
                'Space ship component',
                'Spicy Hungarian meat dish',
                'Sport for zombies',
                'Stage name of a dominatrix',
                'Strange place for sex',
                'Synthetic recreational drug',
                'The eighth Wonder of the Ancient World',
                'The eleventh discipline at the decathlon',
                'Title of an erotic movie',
                'Toilet cleaner',
                'Too much alcohol causes...',
                'Tunesian national dish',
                'Turkish appetiser',
                'Type of coffee from Central America',
                'Unusual killing tool',
                'Urologist\'s appliance',
                'Vietnamese fertility goddess',
                'Volcano on a Caribbean island',
                'Watch out! It\'s a...',
                'What a politician does in secret',
                'What my grandma always warned me about',
            ],
            online: [
                '"No!" in Swahili',
                'A terrible crime',
                'Antique furniture',
                'Asian microstate',
                'Can be ridden',
                'Cellphone manufacturer',
                'Compliment',
                'Condiment',
                'Cough syrup',
                'Disney character',
                'Fashionable headgear',
                'Firearm',
                'Fragrant flower',
                'German novelist',
                'Haha! It\'s a...',
                'Has long hair',
                'Hollywood actor',
                'Horror novel',
                'Internal organ',
                'Is sold in cans',
                'Long-lost Grimm fairy tale',
                'Name of a Pokémon',
                'Name of a Wrestler',
                'Other word for "crazy"',
                'Popular boys\' name',
                'Popular girls\' name',
                'Popular hashtag',
                'Product in a Swedish furniture store',
                'Social Media Platform',
                'Something people like to collect',
                'Something soft and fluffy',
                'Something square-shaped',
                'Something typically red',
                'Sound made when dying',
                'Sound made when sneezing',
                'Travelling circus',
                'Youth slang word of the year',
            ],
        },
    },
    tiles: {
        de: {
            consonants: [
                'B', 'B',
                'C', 'C',
                'D', 'D', 'D', 'D', 'D',
                'F', 'F',
                'G', 'G', 'G', 'G',
                'H', 'H', 'H', 'H', 'H',
                'J',
                'K', 'K',
                'L', 'L', 'L', 'L',
                'M', 'M', 'M', 'M', 'M',
                'N', 'N', 'N', 'N', 'N', 'N', 'N', 'N', 'N', 'N', 'N',
                'P',
                'Q',
                'R', 'R', 'R', 'R', 'R', 'R', 'R',
                'S', 'S', 'S', 'S', 'S', 'S', 'S', 'S',
                'T', 'T', 'T', 'T', 'T', 'T', 'T',
                'V',
                'W',
                'X',
                'Y',
                'Z',
            ],
            vowels: [
                'A', 'A', 'A', 'A', 'A', 'A',
                'Ä',
                'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E',
                'I', 'I', 'I', 'I', 'I', 'I', 'I',
                'O', 'O', 'O', 'O',
                'Ö',
                'U', 'U', 'U', 'U', 'U', 'U', 'U',
                'Ü',
            ],
        },
        en: {
            consonants: [
                'B', 'B',
                'C', 'C',
                'D', 'D', 'D', 'D', 'D', 'D',
                'F', 'F',
                'G', 'G', 'G', 'G',
                'H', 'H',
                'J',
                'K',
                'L', 'L', 'L', 'L', 'L', 'L',
                'M', 'M',
                'N', 'N', 'N', 'N', 'N', 'N', 'N', 'N', 'N',
                'P', 'P',
                'Q',
                'R', 'R', 'R', 'R', 'R', 'R', 'R', 'R', 'R',
                'S', 'S', 'S', 'S', 'S', 'S',
                'T', 'T', 'T', 'T', 'T', 'T', 'T', 'T', 'T',
                'V', 'V',
                'W', 'W',
                'X',
                'Y', 'Y',
                'Z',
            ],
            vowels: [
                'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A',
                'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E',
                'I', 'I', 'I', 'I', 'I', 'I', 'I', 'I', 'I', 'I',
                'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O',
                'U', 'U', 'U', 'U',
            ],
        },
    },
};
