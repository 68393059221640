import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'Draggable';

const Sortable = (props) => {
    const TILE_SIZE = 74 * props.scale;
    const margin = props.margin * props.scale;
    const sortableRef = useRef();
    const [draggingIndex, setDraggingIndex] = useState(null);
    const [droppingIndex, setDroppingIndex] = useState(null);
    const [draggingSortable, setDraggingSortable] = useState(null);
    const [droppingSortable, setDroppingSortable] = useState(null);
    const [dropping, setDropping] = useState(false);
    const [clicked, setClicked] = useState(props.name === 'pool');
    const [originalX, setOriginalX] = useState(0);
    const [originalY, setOriginalY] = useState(0);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [draggables, setDraggables] = useState(null);
    const [isOriginalSortable, setIsOriginalSortable] = useState(false);

    useEffect(() => {
        window.addEventListener('sortable-drag-start', onSortableDragStart);
        window.addEventListener('sortable-drag', onSortableDrag);
        window.addEventListener('sortable-drag-end', onSortableDragEnd);

        return () => {
            window.removeEventListener('sortable-drag-start', onSortableDragStart);
            window.removeEventListener('sortable-drag', onSortableDrag);
            window.removeEventListener('sortable-drag-end', onSortableDragEnd);
        };
    });

    const onSortableDragStart = (event) => {
        if (event.detail.group !== props.group || event.detail.name === props.name) {
            return;
        }

        setDraggables(event.detail.items);
        setDraggingIndex(event.detail.index);
        setDroppingIndex(event.detail.index);
        setDraggingSortable(event.detail.name);
        setDroppingSortable(event.detail.name);
    };

    const onSortableDrag = (event) => {
        if (event.detail.group !== props.group || event.detail.name === props.name) {
            return;
        }

        setDroppingIndex(event.detail.dropTarget.index);
        setDroppingSortable(event.detail.dropTarget.name);
    };

    const onSortableDragEnd = (event) => {
        if (event.detail.group !== props.group || event.detail.name === props.name) {
            return;
        }

        setDropping(true);

        window.setTimeout(() => {
            setDraggingIndex(null);
            setDroppingIndex(null);
            setDraggingSortable(null);
            setDroppingSortable(null);
            setDropping(false);
            setOffsetX(0);
            setOffsetY(0);
            setDraggables(null);
        }, 300);
    };

    const onDragStartHandler = (index) => (event) => {
        const sortables = props.group
            ? document.querySelectorAll(`[data-sortable-group="${props.group}"]`)
            : [event.currentTarget.parentNode];
        const draggableItems = Array.from(sortables).map((sortable) => {
            const items = Array.from(sortable.querySelectorAll('.draggable')).map((draggable) => {
                const rect = draggable.getBoundingClientRect();

                return {
                    bottom: rect.bottom,
                    height: rect.height,
                    left: rect.left,
                    right: rect.right,
                    top: rect.top,
                    width: rect.width,
                };
            });
            const sortableRect = sortable.getBoundingClientRect();

            if (sortable.dataset.sortableName !== props.name) {
                const offset = ((items.length + 1) * (TILE_SIZE + margin * 2)) / 2;

                items.splice(0, 0, {
                    bottom: (sortableRect.top + sortableRect.height / 2) + (TILE_SIZE / 2),
                    height: TILE_SIZE,
                    left: (sortableRect.left + sortableRect.width / 2) - (TILE_SIZE / 2) - offset,
                    right: (sortableRect.left + sortableRect.width / 2) + (TILE_SIZE / 2) - offset,
                    top: (sortableRect.top + sortableRect.height / 2) - (TILE_SIZE / 2),
                    width: TILE_SIZE,
                });
            }

            return {
                name: sortable.dataset.sortableName,
                sortableRect,
                items,
            };
        });

        setIsOriginalSortable(true);
        setDraggables(draggableItems);
        setDraggingIndex(index);
        setDroppingIndex(index);
        setDraggingSortable(props.name);
        setDroppingSortable(props.name);
        setOriginalX(event.touches ? event.touches[0].clientX : event.clientX);
        setOriginalY(event.touches ? event.touches[0].clientY : event.clientY);

        window.dispatchEvent(new CustomEvent('sortable-drag-start', {
            detail: { group: props.group, index, name: props.name, items: draggableItems },
        }));
    };

    const onDrag = (event) => {
        const clientX = event.touches ? event.touches[0].clientX : event.clientX;
        const clientY = event.touches ? event.touches[0].clientY : event.clientY;
        const x = clientX - originalX;
        const y = clientY - originalY;

        setOffsetX(x);
        setOffsetY(y);

        if (clicked) {
            setClicked(Math.abs(x) <= 3 && Math.abs(y) <= 3 && props.name === 'pool');
        }

        const dropTarget = draggables.reduce((result, { name, items, sortableRect }) => {
            const index = items.findIndex((draggable) => {
                const offset = draggingSortable === droppingSortable ? 0 : draggable.width / 2;

                return (
                    clientX >= draggable.left + offset
                    && clientX <= draggable.right + offset
                    && clientY >= draggable.top
                    && clientY <= draggable.bottom
                );
            });

            if (index >= 0) {
                return { name, index };
            }

            const offset = ((items.length - 1) * (TILE_SIZE + margin * 4)) / 2;

            if (
                clientX >= sortableRect.left
                && clientX <= sortableRect.left + sortableRect.width / 2 - offset
                && clientY >= sortableRect.top
                && clientY <= sortableRect.bottom
            ) {
                return { name, index: 0 };
            }

            if (
                clientX >= sortableRect.left + sortableRect.width / 2 + offset
                && clientX <= sortableRect.right
                && clientY >= sortableRect.top
                && clientY <= sortableRect.bottom
            ) {
                return { name, index: items.length - 1 };
            }

            return result;
        }, null);

        if (dropTarget) {
            setDroppingIndex(dropTarget.index);
            setDroppingSortable(dropTarget.name);

            window.dispatchEvent(new CustomEvent('sortable-drag', {
                detail: { group: props.group, dropTarget, name: props.name },
            }));
        }
    };

    const onDragEnd = () => {
        const draggingSortableIndex = draggables.findIndex(({ name }) => name === draggingSortable);
        let droppingSortableIndex = draggables.findIndex(({ name }) => name === droppingSortable);
        let modifiedDroppingSortable = droppingSortable;
        let modifiedDroppingIndex = droppingIndex;

        if (clicked) {
            modifiedDroppingSortable = 'word';
            droppingSortableIndex = draggables.findIndex(({ name }) => name === modifiedDroppingSortable);
            modifiedDroppingIndex = draggables[droppingSortableIndex].items.length - 1;

            setDroppingSortable(modifiedDroppingSortable);
            setDroppingIndex(modifiedDroppingIndex);

            window.dispatchEvent(new CustomEvent('sortable-drag', {
                detail: {
                    group: props.group,
                    dropTarget: {
                        index: modifiedDroppingIndex,
                        name: modifiedDroppingSortable,
                    },
                    name: props.name,
                },
            }));
        }

        const dragItems = draggables[draggingSortableIndex].items;
        const dropItems = draggables[droppingSortableIndex].items;
        let offset = 0;

        if (draggingSortable !== modifiedDroppingSortable) {
            offset = dropItems[modifiedDroppingIndex].width / 2 + margin;
        }

        setDropping(true);
        setOffsetX((dropItems[modifiedDroppingIndex].left - dragItems[draggingIndex].left) + offset);
        setOffsetY(dropItems[modifiedDroppingIndex].top - dragItems[draggingIndex].top);

        window.dispatchEvent(new CustomEvent('sortable-drag-end', {
            detail: { group: props.group, name: props.name },
        }));

        window.setTimeout(() => {
            if (modifiedDroppingIndex !== draggingIndex || draggingSortableIndex !== droppingSortableIndex) {
                props.onChange({
                    from: {
                        name: draggingSortable,
                        index: draggingIndex,
                    },
                    to: {
                        name: modifiedDroppingSortable,
                        index: modifiedDroppingIndex,
                    },
                });
            }

            setDraggingIndex(null);
            setDroppingIndex(null);
            setDraggingSortable(null);
            setDroppingSortable(null);
            setDropping(false);
            setClicked(props.name === 'pool');
            setOffsetX(0);
            setOffsetY(0);
            setDraggables(null);
            setIsOriginalSortable(false);
        }, 300);
    };

    const draggableStyleHandler = (index) => {
        if (!isOriginalSortable) {
            index += 1;
        }

        if (index === draggingIndex && isOriginalSortable) {
            return {
                zIndex: 2,
                transform: `translate(${offsetX / props.scale}px, ${offsetY / props.scale}px)`,
                transition: dropping ? 'transform 300ms' : null,
            };
        }

        if (draggingIndex !== null && droppingIndex !== null) {
            const { items } = draggables.find(({ name }) => name === props.name);
            const originalRect = items[index];
            let targetRect;
            let offset = 0;

            if (draggingSortable !== droppingSortable) {
                if (draggingSortable === props.name) {
                    if (index < draggingIndex) {
                        offset = originalRect.width / 2 + margin;
                    } else if (index > draggingIndex) {
                        offset = -originalRect.width / 2 - margin;
                    }

                    return {
                        transform: `translateX(${offset / props.scale}px)`,
                        transition: 'transform 300ms',
                        zIndex: 1,
                    };
                }

                if (droppingSortable === props.name) {
                    if (index <= droppingIndex) {
                        offset = -originalRect.width / 2 - margin;
                    } else if (index > droppingIndex) {
                        offset = originalRect.width / 2 + margin;
                    }

                    return {
                        transform: `translateX(${offset / props.scale}px)`,
                        transition: 'transform 300ms',
                        zIndex: 1,
                    };
                }
            }

            if (draggingSortable !== props.name) {
                return {
                    transition: 'transform 300ms',
                };
            }

            if (
                droppingIndex > draggingIndex
                && index <= droppingIndex
                && index > draggingIndex
            ) {
                targetRect = items[index - 1];
            } else if (
                droppingIndex < draggingIndex
                && index >= droppingIndex
                && index < draggingIndex
            ) {
                targetRect = items[index + 1];
            } else if (droppingIndex === draggingIndex) {
                targetRect = originalRect;
            } else {
                return {
                    transition: 'transform 300ms',
                    zIndex: 1,
                };
            }

            return {
                transform: `translate(${(targetRect.left - originalRect.left) / props.scale}px, ${(targetRect.top - originalRect.top) / props.scale}px)`,
                transition: 'transform 300ms',
                zIndex: 1,
            };
        }

        return null;
    };

    const placeholderStyleHandler = (draggable, index) => {
        const zoomerRect = sortableRef.current.closest('[style^="transform"]').getBoundingClientRect();
        let offset = 0;

        if (!isOriginalSortable) {
            if (index <= droppingIndex) {
                offset = draggable.width / 2 + margin;
            } else if (index > droppingIndex) {
                offset = -draggable.width / 2 - margin;
            }
        }

        return {
            height: (draggable.height / props.scale) + 6,
            left: (draggable.left + offset - zoomerRect.left) / props.scale,
            opacity: index === droppingIndex && droppingSortable === props.name ? 1 : 0,
            width: draggable.width / props.scale,
            top: (draggable.top - zoomerRect.top) / props.scale,
        };
    };

    const renderPlaceholders = () => {
        if (!draggables) {
            return null;
        }

        const { items } = draggables.find(({ name }) => name === props.name);

        return items.map((draggable, index) => (
            <div
                key={index}
                className="sortable__placeholder"
                style={placeholderStyleHandler(draggable, index)}
            />
        ));
    };

    return (
        <div
            ref={sortableRef}
            className={props.className}
            data-sortable-group={props.group}
            data-sortable-name={props.name}
            style={props.style}
        >
            {React.Children.map(props.children, (child, index) => (
                <Draggable
                    key={index}
                    disabled={props.disabled || dropping}
                    handle={props.handle}
                    ignoreSelector={props.ignoreSelector}
                    onDrag={onDrag}
                    onDragEnd={onDragEnd}
                    onDragStart={onDragStartHandler(index)}
                    style={{
                        ...draggableStyleHandler(index),
                        margin: `0 ${margin}px`,
                    }}
                >
                    {child}
                </Draggable>
            ))}

            {renderPlaceholders()}
        </div>
    );
};

Sortable.defaultProps = {
    className: null,
    disabled: false,
    ignoreSelector: null,
    handle: null,
    group: null,
    margin: 0,
    name: 'none',
    onChange: () => null,
    scale: 1,
    style: {},
};

Sortable.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    group: PropTypes.string,
    handle: PropTypes.string,
    ignoreSelector: PropTypes.string,
    margin: PropTypes.number,
    name: PropTypes.string,
    onChange: PropTypes.func,
    scale: PropTypes.number,
    style: PropTypes.object,
};

export default Sortable;
