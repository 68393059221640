import { useMutation } from 'apollo-augmented-hooks';

const mutation = `
    mutation joinGame($input: JoinGameInput!) {
        joinGame(input: $input) {
            token
            game {
                id
                players {
                    id
                    name
                    color
                    isAdmin
                    connected
                }
            }
            player {
                id
                game {
                    id
                }
            }
        }
    }
`;

export default () => {
    const mutate = useMutation(mutation);

    return (input) => (
        mutate({
            input,
            modifiers: [{
                newFields: {
                    player: ({ item, toReference }) => (
                        toReference(item.player)
                    ),
                },
            }],
        })
    );
};
