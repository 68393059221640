import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Letter = (props) => (
    <div
        className={classnames(
            'ui-letter',
            {
                'ui-letter--small': props.small,
                'ui-letter--space': props.children === ' ',
            }
        )}
    >
        <div>{props.children}</div>
    </div>
);

Letter.defaultProps = {
    small: false,
};

Letter.propTypes = {
    children: PropTypes.string.isRequired,
    small: PropTypes.bool,
};

export default Letter;
