import { useSubscription } from 'apollo-augmented-hooks';

const subscription = `
    subscription gameStarted {
        gameStarted {
            id
            status
            actions {
                id
                type
                payload
                randomSeed
                createdAt
                player {
                    id
                }
            }
        }
    }
`;

export default () => (
    useSubscription(subscription)
);
