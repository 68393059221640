import { useState } from 'react';
import { useSpring, useSpringRef, useSprings, useChain } from 'react-spring';

export default (players, scale) => {
    const [animationDone, setAnimationDone] = useState(false);
    const textSpringRef = useSpringRef();
    const textSpring = useSpring({
        ref: textSpringRef,
        from: {
            y: -100 / scale,
        },
        to: {
            y: 0,
        },
    });
    const playerSpringRef = useSpringRef();
    const playerSpring = useSprings(players.length, players.map((player, index) => ({
        ref: playerSpringRef,
        delay: index * 100,
        from: {
            y: 100 / scale,
        },
        to: {
            y: 0,
        },
    })));
    const buttonSpringRef = useSpringRef();
    const buttonSpring = useSpring({
        ref: buttonSpringRef,
        from: {
            y: 100 / scale,
        },
        to: {
            y: 0,
        },
        onRest: () => {
            setAnimationDone(true);
        },
    });

    useChain([textSpringRef, playerSpringRef, buttonSpringRef], [0, 1.5, 4]);

    return {
        anim: {
            text: textSpring,
            players: playerSpring,
            button: buttonSpring,
        },
        done: animationDone,
    };
};
