import React, { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useSprings, animated } from 'react-spring';

const PointSet = (props) => {
    const affectScore = useRef(props.affectScore);
    const isMounted = useRef(false);
    const [springs] = useSprings(props.playerIds.length, (index) => {
        const from = document.querySelector(`[data-point-from="${props.playerIds[index]}"]`);
        const fromRect = from.getBoundingClientRect();
        const fromX = fromRect.left + fromRect.width / 2 - 12;
        const fromY = fromRect.top + fromRect.height / 2 - 12;
        const to = document.querySelector(`[data-point-to="${props.playerIds[index]}"] img`);
        const toRect = to.getBoundingClientRect();

        return {
            reset: true,
            delay: index * 500,
            from: {
                x: (fromX + 64),
                y: (fromY - 128),
                rotate: -45,
                scale: 6,
                opacity: 0,
                textShadow: '0 1px 1px rgba(0, 0, 0, .5)',
            },
            to: [
                {
                    x: fromX,
                    y: fromY,
                    rotate: 0,
                    opacity: 1,
                    scale: 1.8,
                    config: { tension: 210 },
                },
                {
                    x: toRect.left,
                    y: toRect.top,
                    scale: 1,
                    textShadow: '0 1px 1px rgba(0, 0, 0, 0)',
                    config: { tension: 200 },
                    onRest: () => {
                        if (affectScore.current && isMounted.current) {
                            window.dispatchEvent(new CustomEvent('scored', {
                                detail: props.playerIds[index],
                            }));
                        }
                    },
                },
                {
                    opacity: 0,
                    onRest: () => {
                        if (index === props.playerIds.length - 1) {
                            props.removePointSet();
                        }
                    },
                },
            ],
        };
    });

    useEffect(() => {
        affectScore.current = props.affectScore;
    }, [props.affectScore]);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    });

    return createPortal(
        springs.map((styles, index) => (
            <animated.div
                key={index}
                className="point-set__point"
                style={styles}
            >
                <img
                    alt=""
                    src="/images/star.png"
                />
            </animated.div>
        )),
        document.body
    );
};

PointSet.propTypes = {
    affectScore: PropTypes.bool.isRequired,
    playerIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    removePointSet: PropTypes.func.isRequired,
};

export default PointSet;
