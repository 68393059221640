import Action from '../classes/Action';
import states from '../states';

export default class extends Action {
    static get id() {
        return 4;
    }

    static isValid({ state, player, payload }) {
        if (state.state !== states.SCORING.id) {
            return false;
        }

        const currentPlayer = state.players.find((item) => (
            item.id === state.game.scoringOrder.filter((playerId) => (
                !state.players.find(({ id }) => id === playerId).scored
            ))[0]
        ));

        if (currentPlayer.id !== player.id) {
            return false;
        }

        if (payload.playerId === player.id) {
            return !player.shownSolution;
        }

        return !player.shownGuesses.includes(payload.playerId);
    }

    static perform({ state, player, payload }) {
        const playerIndex = state.players.findIndex(({ id }) => id === player.id);
        const nextPlayerState = { ...state.players[playerIndex] };
        const maxPointsForSolution = 5;

        if (payload.playerId === player.id) {
            nextPlayerState.shownSolution = true;
        } else {
            nextPlayerState.shownGuesses.push(payload.playerId);
        }

        let players = [
            ...state.players.slice(0, playerIndex),
            { ...nextPlayerState },
            ...state.players.slice(playerIndex + 1),
        ];

        players = players.map((item) => {
            let points = 0;
            let pointsForSolution = 0;

            if (payload.playerId === player.id) {
                if (item.id === player.id) {
                    points = Math.min(
                        players.reduce((result, { id, guesses }) => (
                            guesses[player.id] === player.category && nextPlayerState.shownGuesses.includes(id) ? result + 1 : result
                        ), 0),
                        maxPointsForSolution,
                    );
                    pointsForSolution = points;
                } else {
                    points = item.guesses[player.id] === player.category && nextPlayerState.shownGuesses.includes(item.id) ? 1 : 0;
                }
            } else if (item.id === player.id) {
                const clickedPlayer = players.find(({ id }) => id === payload.playerId);

                if (item.pointsGainedForSolutionThisRound < maxPointsForSolution) {
                    points = clickedPlayer.guesses[player.id] === player.category && nextPlayerState.shownSolution ? 1 : 0;
                    pointsForSolution = points;
                }
            } else if (payload.playerId === item.id) {
                points = item.guesses[player.id] === player.category && nextPlayerState.shownSolution ? 1 : 0;
            }

            return {
                ...item,
                score: item.score + points,
                previousScore: item.score,
                pointsGainedForSolutionThisRound: item.pointsGainedForSolutionThisRound + pointsForSolution,
            };
        });

        const { shownSolution, shownGuesses } = players.find(({ id }) => id === player.id);

        return {
            ...state,
            players,
            state: shownSolution && state.players.every(({ id }) => id === player.id || shownGuesses.includes(id))
                ? states.SCORING_NEXT_PLAYER.id
                : states.SCORING.id,
        };
    }
}
