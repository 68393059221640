import { useState } from 'react';
import { useSpring, useSpringRef, useSprings, useChain } from 'react-spring';

export default (tiles, scale) => {
    const [animationDone, setAnimationDone] = useState(false);
    const categorySpringRef = useSpringRef();
    const categorySpring = useSpring({
        ref: categorySpringRef,
        from: {
            y: -100 / scale,
        },
        to: {
            y: 0,
        },
    });
    const tileSpringRef = useSpringRef();
    const tileSpring = useSprings(tiles.length, tiles.map((tile, index) => ({
        ref: tileSpringRef,
        delay: index * 50,
        from: {
            xy: animationDone ? [0, 0] : [100 / scale, -20 / scale],
        },
        to: {
            xy: [0, 0],
        },
    })));
    const wordSpringRef = useSpringRef();
    const wordSpring = useSpring({
        ref: wordSpringRef,
        from: {
            y: 100 / scale,
        },
        to: {
            y: 0,
        },
    });
    const shuffleSpringRef = useSpringRef();
    const shuffleSpring = useSpring({
        ref: shuffleSpringRef,
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        },
        onRest: () => {
            setAnimationDone(true);
        },
    });

    useChain([categorySpringRef, tileSpringRef, wordSpringRef, shuffleSpringRef], [0, 0.5, 1, 1.2]);

    return {
        anim: {
            category: categorySpring,
            tiles: tileSpring,
            word: wordSpring,
            shuffle: shuffleSpring,
        },
        done: animationDone,
    };
};
