import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { animated } from 'react-spring';
import useToggle from 'hooks/useToggle';
import useTranslate from 'hooks/useTranslate';
import Feedback from 'Feedback';
import Logo from 'atoms/Logo';
import Paragraph from 'atoms/Paragraph';
import Headline from 'atoms/Headline';
import IconButton from 'atoms/IconButton';
import Modal from 'molecules/Modal';

const Header = (props) => {
    const t = useTranslate();
    const [isHelpOpen, openHelp, closeHelp] = useToggle(false);
    const [isPurchaseOpen, openPurchase, closePurchase] = useToggle(false);
    const [isFeedbackOpen, openFeedback, closeFeedback] = useToggle(false);
    const [isDonationOpen, openDonation, closeDonation] = useToggle(false);

    useEffect(() => {
        if (!isDonationOpen) {
            return;
        }

        window.PayPal.Donation.Button({
            env: 'production',
            /* eslint-disable-next-line camelcase */
            hosted_button_id: 'QCLTM4GURG9RC',
            image: {
                src: t('header.help.donate.button.url'),
                alt: t('header.help.donate.button.alt'),
                title: t('header.help.donate.button.alt'),
            },
        }).render('.header__paypal');
    }, [isDonationOpen]);

    return (
        <animated.div
            className="header"
            style={{ visibility: props.visibility }}
        >
            <Logo />

            <div className="header__help">
                <IconButton
                    icon="question"
                    label={t('header.help.rules')}
                    onClick={openHelp}
                />

                <IconButton
                    icon="shopping-cart"
                    label={t('header.help.purchase')}
                    onClick={openPurchase}
                />

                <IconButton
                    icon="comment"
                    label={t('header.help.feedback')}
                    onClick={openFeedback}
                />

                <IconButton
                    highlighted
                    icon="euro-sign"
                    label={t('header.help.donate')}
                    onClick={openDonation}
                />
            </div>

            <Modal
                close={closeHelp}
                headline={t('header.help.rules.headline', { title: <span>Krazy Wordz</span> })}
                isOpen={isHelpOpen}
            >
                <Paragraph>{t('header.help.rules.p1')}</Paragraph>
                <Paragraph>{t('header.help.rules.p2')}</Paragraph>
                <Headline><span>1</span> {t('header.help.rules.h1')}</Headline>
                <Paragraph>{t('header.help.rules.p3')}</Paragraph>
                <Headline><span>2</span> {t('header.help.rules.h2')}</Headline>
                <Paragraph>{t('header.help.rules.p4')}</Paragraph>
                <Headline><span>3</span> {t('header.help.rules.h3')}</Headline>
                <Paragraph>{t('header.help.rules.p5')}</Paragraph>
                <Paragraph>{t('header.help.rules.p6')}</Paragraph>
            </Modal>

            <Feedback
                close={closeFeedback}
                isOpen={isFeedbackOpen}
            />

            <Modal
                close={closePurchase}
                headline={t('header.help.purchase.headline', { title: <span>Krazy Wordz</span> })}
                isOpen={isPurchaseOpen}
            >
                <img
                    alt="Produktbild"
                    className="header__help-image"
                    src="/images/boardgame.jpeg"
                />

                <Paragraph>
                    {t('header.help.purchase.p1')}
                </Paragraph>

                <Paragraph>
                    <a
                        href="https://www.ravensburger.de/produkte/spiele/familienspiele/krazy-wordz-26837/index.html"
                        rel="noreferrer noopener"
                        target="_blank"
                    >
                        {t('header.help.purchase.link1')}
                    </a>
                    <br />

                    <a
                        href="https://www.spiele-offensive.de/Spiel/Krazy-Wordz-2-1025036.html"
                        rel="noreferrer noopener"
                        target="_blank"
                    >
                        {t('header.help.purchase.link2')}
                    </a>
                    <br />

                    <a
                        href="https://www.amazon.de/Ravensburger-26837-Krazy-Wordz-Children/dp/B084DGWRYC/"
                        rel="noreferrer noopener"
                        target="_blank"
                    >
                        {t('header.help.purchase.link3')}
                    </a>
                </Paragraph>
            </Modal>

            <Modal
                close={closeDonation}
                confirmLabel={t('header.help.donate.confirm')}
                headline={t('header.help.donate.headline')}
                isOpen={isDonationOpen}
            >
                <Paragraph>{t('header.help.donate.p1', { title: <span>Krazy Wordz</span> })}</Paragraph>

                <div className="header__paypal" />
            </Modal>
        </animated.div>
    );
};

Header.propTypes = {
    visibility: PropTypes.object.isRequired,
};

export default Header;
