import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Loader from 'atoms/Loader';

const Button = (props) => (
    <div
        className={classnames(
            'ui-button',
            {
                'ui-button--disabled': props.disabled,
                'ui-button--loading': props.loading,
            }
        )}
        onClick={props.onClick}
    >
        <div className="ui-button__label">
            {props.label}
        </div>

        <div className="ui-button__loader">
            <Loader />
        </div>
    </div>
);

Button.defaultProps = {
    disabled: false,
    loading: false,
};

Button.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

export default Button;
