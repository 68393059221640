import Action from '../classes/Action';
import states from '../states';

export default class extends Action {
    static get id() {
        return 2;
    }

    static isValid({ state, payload }) {
        if (state.state !== states.WORD_BUILDING.id) {
            return false;
        }

        return payload.tiles.word.length > 0;
    }

    static perform({ state, player, payload, createdAt }) {
        const playerIndex = state.players.findIndex(({ id }) => id === player.id);
        const players = [
            ...state.players.slice(0, playerIndex),
            {
                ...state.players[playerIndex],
                tiles: payload.tiles,
                submittedAt: createdAt,
            },
            ...state.players.slice(playerIndex + 1),
        ];

        return {
            ...state,
            players,
            state: players.every(({ tiles }) => tiles.word.length > 0) ? states.GUESSING.id : states.WORD_BUILDING.id,
        };
    }
}
