import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useTransition } from 'react-spring';
import AuthContext from 'contexts/auth';
import { AUTH_TYPE_PLAYER } from 'auth';
import Game from './App/Game';
import Main from './App/Main';

const App = () => {
    const location = useLocation();
    const transitions = useTransition(location, {
        from: { opacity: 0, y: 64, visibility: 'visible' },
        enter: { opacity: 1, y: 0, visibility: 'visible' },
        leave: { opacity: 0, y: 64, visibility: 'hidden' },
        trail: 100,
    });

    return (
        <div className="app">
            {transitions((styles, item) => (
                <Switch location={item}>
                    <Route path="/:code">
                        <AuthContext.Provider value={AUTH_TYPE_PLAYER}>
                            <Game spring={styles} />
                        </AuthContext.Provider>
                    </Route>

                    <Route path="/">
                        <Main spring={styles} />
                    </Route>
                </Switch>
            ))}
        </div>
    );
};

export default App;
