import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { animated, useSprings } from 'react-spring';
import { useApolloClient } from '@apollo/client';
import useTranslate from 'hooks/useTranslate';
import useStartGame from 'hooks/graphql/mutations/startGame';
import useJoinGame from 'hooks/graphql/mutations/joinGame';
import useCreateDummyPlayer from 'hooks/graphql/mutations/createDummyPlayer';
import { setToken, AUTH_TYPE_PLAYER } from 'auth';
import options from 'shared/options';
import CardSet from 'CardSet';
import Box from 'molecules/Box';
import Admin from './Lobby/Admin';
import Player from './Lobby/Player';
import Header from '../Header';
import Footer from '../Footer';

const Lobby = (props) => {
    const t = useTranslate();
    const client = useApolloClient();
    const [loading, setLoading] = useState(false);
    const [cardSetState, setCardSetState] = useState({ isOpen: false, type: 'family' });
    const startGame = useStartGame();
    const joinGame = useJoinGame();
    const createDummyPlayer = useCreateDummyPlayer();
    const isSuperAdmin = window.location.href.includes('ichdarfalles');
    const springs = useSprings(10, Array(10).fill(null).map((_, index) => ({
        occupiedOpacity: props.game.players[index] ? 1 : 0,
        occupiedY: props.game.players[index] ? 0 : -8,
        occupiedBorderColor: props.game.players[index]?.color || 'transparent',
        freeOpacity: props.game.players[index] ? 0 : 1,
        freeY: props.game.players[index] ? 8 : 0,
    })));

    const openCardSetHandler = (type) => () => {
        setCardSetState({ isOpen: true, type });
    };

    const closeCardSet = () => {
        setCardSetState((previous) => ({ ...previous, isOpen: false }));
    };

    const handleStartGame = async () => {
        setLoading(true);

        await startGame();
    };

    const handleJoinGame = async (name, color) => {
        const { data } = await joinGame({
            code: props.game.code,
            player: {
                name,
                color,
            },
        });

        setToken(AUTH_TYPE_PLAYER, data.joinGame.token);

        client.restartWebSocketConnection();
    };

    const handleCreateDummyPlayer = async () => {
        if (!isSuperAdmin) {
            return;
        }

        await createDummyPlayer();
    };

    const renderSettings = () => {
        if (props.player?.isAdmin) {
            return (
                <Admin
                    game={props.game}
                    loading={loading}
                    openCardSetHandler={openCardSetHandler}
                    startGame={handleStartGame}
                />
            );
        }

        return (
            <Player
                game={props.game}
                joinGame={handleJoinGame}
                openCardSetHandler={openCardSetHandler}
                player={props.player?.game.id === props.game.id ? props.player : null}
            />
        );
    };

    return (
        <div className="lobby">
            <Header visibility={props.spring.visibility} />

            <animated.div
                className="lobby__content"
                style={{ opacity: props.spring.opacity, y: props.spring.y }}
            >
                <Box width={859}>
                    <div className="lobby__box-content">
                        <div
                            className="lobby__players"
                            onClick={handleCreateDummyPlayer}
                        >
                            {springs.map((styles, index) => (
                                <div
                                    key={index}
                                    className="lobby__player"
                                >
                                    <animated.div
                                        className="lobby__player-occupied"
                                        style={{
                                            opacity: styles.occupiedOpacity,
                                            y: styles.occupiedY,
                                            borderColor: styles.occupiedBorderColor,
                                        }}
                                    >
                                        {props.game.players[index]?.name}
                                    </animated.div>

                                    <animated.div
                                        className="lobby__player-free"
                                        style={{
                                            opacity: styles.freeOpacity,
                                            y: styles.freeY,
                                        }}
                                    >
                                        {t('lobby.freeSlot')}
                                    </animated.div>
                                </div>
                            ))}
                        </div>

                        <div className="lobby__settings">
                            {renderSettings()}
                        </div>
                    </div>
                </Box>

                <Footer />
            </animated.div>

            <CardSet
                close={closeCardSet}
                isOpen={cardSetState.isOpen}
                label={t(options.cardSets.values.find(({ value }) => value === cardSetState.type).label)}
                type={cardSetState.type}
            />
        </div>
    );
};

Lobby.defaultProps = {
    player: null,
};

Lobby.propTypes = {
    game: PropTypes.object.isRequired,
    player: PropTypes.object,
    spring: PropTypes.object.isRequired,
};

export default Lobby;
