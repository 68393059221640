import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const FormItem = (props) => (
    <div
        className={classnames(
            'ui-form-item',
            { 'ui-form-item--centered': props.centered }
        )}
    >
        {props.label && (
            <div className="ui-form-item__label">
                {props.label}
            </div>
        )}

        {props.children}
    </div>
);

FormItem.defaultProps = {
    centered: false,
    label: null,
};

FormItem.propTypes = {
    centered: PropTypes.bool,
    children: PropTypes.node.isRequired,
    label: PropTypes.string,
};

export default FormItem;
