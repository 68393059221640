import { useMutation } from 'apollo-augmented-hooks';
import { GAME } from '../fragments/Game';
import { PLAYER } from '../fragments/Player';

const mutation = `
    mutation createGame($input: CreateGameInput!) {
        createGame(input: $input) {
            token
            game {
                ${GAME}
            }
            player {
                ${PLAYER}
            }
        }
    }
`;

export default () => {
    const mutate = useMutation(mutation);

    return (input) => (
        mutate({
            input,
            modifiers: [{
                newFields: {
                    player: ({ item, toReference }) => (
                        toReference(item.player)
                    ),
                    game: {
                        variables: ({ item }) => ({ code: item.game.code }),
                        modify: ({ item, toReference }) => (
                            toReference(item.game)
                        ),
                    },
                },
            }],
        })
    );
};
