import Action from '../classes/Action';
import states from '../states';
import assets from '../assets';

export default class extends Action {
    static get id() {
        return 1;
    }

    static get isServerAction() {
        return true;
    }

    static isValid({ state }) {
        return state.state === states.ROUND_PREPARATION.id;
    }

    static perform({ state, randomizer, language }) {
        const consonants = [...assets.tiles[language].consonants];
        const vowels = [...assets.tiles[language].vowels];

        return {
            ...state,
            game: {
                ...state.game,
                round: state.game.round + 1,
                dummyCategories: randomizer.draw(state.game.categories, 6 - state.players.length),
                scoringOrder: null,
            },
            players: state.players.map((rest) => ({
                ...rest,
                category: randomizer.draw(state.game.categories)[0],
                tiles: {
                    pool: [
                        ...randomizer.draw(consonants, 6),
                        ...randomizer.draw(vowels, 3),
                        ...Array(2).fill(' '),
                    ],
                    word: [],
                },
                guesses: {},
                shownSolution: false,
                shownGuesses: [],
                scored: false,
                pointsGainedForSolutionThisRound: 0,
            })),
            state: states.WORD_BUILDING.id,
        };
    }
}
