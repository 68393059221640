export default {
    rounds: {
        type: 'number',
        label: 'lobby.rounds',
        min: 1,
        max: 12,
        default: 6,
    },
    cardSets: {
        type: 'checkbox',
        label: 'lobby.cardSets',
        values: [{
            value: 'family',
            label: 'lobby.cardSets.family',
        }, {
            value: 'adult',
            label: 'lobby.cardSets.adult',
        }, {
            value: 'online',
            label: 'lobby.cardSets.online',
        }],
        default: ['family', 'adult', 'online'],
    },
};
