import React from 'react';
import PropTypes from 'prop-types';

const Box = (props) => (
    <div
        className="ui-box"
        style={{ width: props.width }}
    >
        {props.children}
    </div>
);

Box.defaultProps = {
    width: null,
};

Box.propTypes = {
    children: PropTypes.node.isRequired,
    width: PropTypes.number,
};

export default Box;
