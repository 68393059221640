import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Logo = (props) => (
    <div
        className={classnames(
            'ui-logo',
            { 'ui-logo--small': props.small }
        )}
    >
        <div className="ui-logo__background" />

        Krazy Wordz
    </div>
);

Logo.defaultProps = {
    small: false,
};

Logo.propTypes = {
    small: PropTypes.bool,
};

export default Logo;
