import clone from 'clone';
import randomizer from '../helpers/randomizer';

export default class Action {
    static get id() {
        throw new Error('Action needs to implement an id getter');
    }

    static get isServerAction() {
        return false;
    }

    static isValid({ state }) {
        if (this.isSetupAction) {
            return !state;
        }

        throw new Error('Action needs to implement an isValid method');
    }

    static perform() {
        throw new Error('Action needs to implement a perform method');
    }

    static prepareAndPerform(game, action, previousState) {
        const player = {
            ...action.player,
            ...previousState?.players.find(({ id }) => id === action.player?.id),
        };

        return this.perform({
            state: previousState ? clone(previousState) : null,
            payload: action.payload,
            player,
            allPlayers: game.players,
            randomizer: action.randomSeed ? randomizer(action.randomSeed) : null,
            options: game.options,
            createdAt: action.createdAt,
            language: game.language,
        });
    }
}
