import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Draggable extends React.Component {
    isMouseDown = false

    UNSAFE_componentWillMount() {
        this.containerRef = React.createRef();
        this.dragEventRef = React.createRef();
    }

    componentDidMount() {
        // Need to attach the events like this because this is the only way to add them
        // non-passively in react, and that is necessary because otherwise we can't prevent
        // background scrolling on iOS >= 11.3
        this.containerRef.current.addEventListener('touchstart', this.onDragStart, { passive: false });
        this.containerRef.current.addEventListener('touchmove', this.onDrag, { passive: false });
        this.containerRef.current.addEventListener('touchend', this.onDragEnd, { passive: false });
    }

    componentWillUnmount() {
        this.containerRef.current.removeEventListener('touchstart', this.onDragStart);
        this.containerRef.current.removeEventListener('touchmove', this.onDrag);
        this.containerRef.current.removeEventListener('touchend', this.onDragEnd);
    }

    shouldIgnore(element) {
        if (!this.props.ignoreSelector || !element.parentNode) {
            return false;
        }

        return element.matches(this.props.ignoreSelector) || this.shouldIgnore(element.parentNode);
    }

    isHandle(element) {
        if (!this.props.handle) {
            return true;
        }

        if (!element.parentNode) {
            return false;
        }

        return element.matches(this.props.handle) || this.isHandle(element.parentNode);
    }

    onDragStart = (event) => {
        if (this.shouldIgnore(event.target) || !this.isHandle(event.target)) {
            return;
        }

        event.preventDefault();

        this.props.onDragStart(event);
    }

    onDrag = (event) => {
        if (this.shouldIgnore(event.target)) {
            return;
        }

        event.preventDefault();

        if (event.type === 'mousemove' && !this.isMouseDown) {
            return;
        }

        this.dragEventRef.current = event;
        this.props.onDrag(event);
    }

    onDragEnd = (event) => {
        if (this.shouldIgnore(event.target)) {
            return;
        }

        this.props.onDragEnd(event, this.dragEventRef.current);
    }

    onMouseDown = (event) => {
        if (this.shouldIgnore(event.target) || !this.isHandle(event.target)) {
            return;
        }

        event.preventDefault();

        this.isMouseDown = true;

        document.addEventListener('mouseup', this.onMouseUp);
        document.addEventListener('mousemove', this.onMouseMove);

        this.props.onDragStart(event);
    }

    onMouseMove = (event) => {
        if (this.shouldIgnore(event.target)) {
            return;
        }

        event.preventDefault();

        this.props.onDrag(event);
    }

    onMouseUp = (event) => {
        if (this.shouldIgnore(event.target)) {
            return;
        }

        this.isMouseDown = false;

        document.removeEventListener('mouseup', this.onMouseUp);
        document.removeEventListener('mousemove', this.onMouseMove);

        this.props.onDragEnd(event);
    }

    render() {
        return (
            <div
                ref={this.containerRef}
                className={classNames(
                    'draggable',
                    { 'draggable--disabled': this.props.disabled }
                )}
                onMouseDown={this.onMouseDown}
                style={this.props.style}
            >
                {this.props.children}
            </div>
        );
    }
}

Draggable.defaultProps = {
    onDragStart: () => null,
    onDrag: () => null,
    onDragEnd: () => null,
    style: null,
    disabled: false,
    ignoreSelector: null,
    handle: null,
};

Draggable.propTypes = {
    onDragStart: PropTypes.func,
    onDrag: PropTypes.func,
    onDragEnd: PropTypes.func,
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    ignoreSelector: PropTypes.string,
    handle: PropTypes.string,
};

export default Draggable;
