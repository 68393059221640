import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { animated } from 'react-spring';
import { useApolloClient } from '@apollo/client';
import LocaleContext from 'contexts/locale';
import useTranslate from 'hooks/useTranslate';
import useCreateGame from 'hooks/graphql/mutations/createGame';
import { setToken, AUTH_TYPE_PLAYER } from 'auth';
import Button from 'atoms/Button';
import ColorPicker from 'atoms/ColorPicker';
import TextField from 'atoms/TextField';
import LanguagePicker from 'molecules/LanguagePicker';
import Box from 'molecules/Box';
import Form, { FormItem } from 'molecules/Form';
import Header from './Header';
import Footer from './Footer';

const languages = ['de', 'en'];

const Main = (props) => {
    const client = useApolloClient();
    const [name, setName] = useState('');
    const [color, setColor] = useState(null);
    const browserLanguage = navigator.language.substring(0, 2);
    const [language, setLanguage] = useState(languages.includes(browserLanguage) ? browserLanguage : 'en');
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const createGame = useCreateGame();
    const t = useTranslate(language);

    const confirm = async () => {
        setLoading(true);

        const { data } = await createGame({
            player: {
                name,
                color,
            },
            language,
        });

        setToken(AUTH_TYPE_PLAYER, data.createGame.token);

        client.restartWebSocketConnection();

        history.push(`/${data.createGame.game.code}`);
    };

    return (
        <LocaleContext.Provider value={language}>
            <div className="main">
                <Header visibility={props.spring.visibility} />

                <animated.div
                    className="main__content"
                    style={{ opacity: props.spring.opacity, y: props.spring.y }}
                >
                    <Box width={496}>
                        <Form>
                            <FormItem label={t('main.form.name')}>
                                <TextField
                                    maxLength={24}
                                    onChange={setName}
                                    value={name}
                                />
                            </FormItem>

                            <FormItem label={t('main.form.color')}>
                                <ColorPicker
                                    onChange={setColor}
                                    value={color}
                                />
                            </FormItem>

                            <FormItem label={t('main.form.language')}>
                                <LanguagePicker
                                    languages={languages}
                                    onChange={setLanguage}
                                    value={language}
                                />
                            </FormItem>

                            <FormItem centered>
                                <Button
                                    disabled={!name.trim() || !color}
                                    label={t('main.button.start')}
                                    loading={loading}
                                    onClick={confirm}
                                />
                            </FormItem>
                        </Form>
                    </Box>

                    <Footer />
                </animated.div>
            </div>
        </LocaleContext.Provider>
    );
};

Main.defaultProps = {
    spring: null,
};

Main.propTypes = {
    spring: PropTypes.object,
};

export default Main;
