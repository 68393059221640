import State from '../classes/State';

export default class extends State {
    static get id() {
        return 5;
    }

    static performAutomatically() {
        const actions = require('../actions').default;

        return actions.END_GAME;
    }
}
