import { useSubscription } from 'apollo-augmented-hooks';
import { useHistory } from 'react-router-dom';
import { setToken, AUTH_TYPE_PLAYER } from 'auth';
import { GAME } from '../../fragments/Game';

const subscription = `
    subscription consecutiveGameCreated {
        consecutiveGameCreated {
            token
            game {
                ${GAME}
            }
            player {
                id
                name
                color
                isAdmin
                connected
                game {
                    id
                }
            }
        }
    }
`;

export default () => {
    const history = useHistory();

    return useSubscription(subscription, {
        modifiers: [{
            fields: {
                player: ({ item, toReference }) => (
                    toReference(item.player)
                ),
            },
            newFields: {
                game: {
                    variables: ({ item }) => ({ code: item.game.code }),
                    modify: ({ item, toReference }) => (
                        toReference(item.game)
                    ),
                },
            },
        }],
        onSubscriptionData: ({ client, subscriptionData }) => {
            setToken(AUTH_TYPE_PLAYER, subscriptionData.data.consecutiveGameCreated.token);

            client.restartWebSocketConnection();

            history.push(`/${subscriptionData.data.consecutiveGameCreated.game.code}`);
        },
    });
};
