import useConsecutiveGameCreated from './game/consecutiveGameCreated';
import useGameJoined from './game/gameJoined';
import useGameStarted from './game/gameStarted';
import useActionsPushed from './game/actionsPushed';
import useGameOptionsChanged from './game/gameOptionsChanged';
import usePlayerConnectionChanged from './game/playerConnectionChanged';

export default () => {
    useConsecutiveGameCreated();
    useGameJoined();
    useGameStarted();
    useActionsPushed();
    useGameOptionsChanged();
    usePlayerConnectionChanged();
};
