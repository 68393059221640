import { useMutation } from 'apollo-augmented-hooks';

const mutation = `
    mutation changeGameOptions($input: ChangeGameOptionsInput!) {
        changeGameOptions(input: $input) {
            id
            options
        }
    }
`;

export default (gameId) => {
    const mutate = useMutation(mutation);

    return (input) => (
        mutate({
            input,
            optimisticResponse: {
                __typename: 'Game',
                id: gameId,
            },
        })
    );
};
