import Action from '../classes/Action';
import states from '../states';

export default class extends Action {
    static get id() {
        return 5;
    }

    static isValid({ state, player }) {
        if (state.state !== states.SCORING_NEXT_PLAYER.id) {
            return false;
        }

        const currentPlayer = state.players.find((item) => (
            item.id === state.game.scoringOrder.filter((playerId) => (
                !state.players.find(({ id }) => id === playerId).scored
            ))[0]
        ));

        return currentPlayer.id === player.id;
    }

    static perform({ state, player, options }) {
        let nextState = states.SCORING.id;
        const playerIndex = state.players.findIndex(({ id }) => id === player.id);
        let players = [
            ...state.players.slice(0, playerIndex),
            {
                ...state.players[playerIndex],
                scored: true,
            },
            ...state.players.slice(playerIndex + 1),
        ];

        players = players.map((item) => ({
            ...item,
            previousScore: item.score,
        }));

        if (players.every(({ scored }) => scored)) {
            if (state.game.round === parseInt(options.rounds, 10)) {
                nextState = states.GAME_END.id;
            } else {
                nextState = states.ROUND_PREPARATION.id;
            }
        }

        return {
            ...state,
            players,
            state: nextState,
        };
    }
}
