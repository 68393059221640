import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import emailValidator from 'email-validator';
import useTranslate from 'hooks/useTranslate';
import useCreateFeedback from 'hooks/graphql/mutations/createFeedback';
import TextField from 'atoms/TextField';
import TextArea from 'atoms/TextArea';
import Paragraph from 'atoms/Paragraph';
import Modal from 'molecules/Modal';
import Form, { FormItem } from 'molecules/Form';

const Feedback = (props) => {
    const t = useTranslate();
    const [loading, setLoading] = useState(false);
    const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false);
    const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const createFeedback = useCreateFeedback();

    useEffect(() => {
        if (props.isOpen) {
            setName('');
            setEmail('');
            setMessage('');
        }
    }, [props.isOpen]);

    const confirm = async () => {
        setLoading(true);

        try {
            await createFeedback({
                code: props.code,
                name: name.trim(),
                email,
                message: message.trim(),
            });

            setLoading(false);
            setIsSuccessAlertOpen(true);
        } catch (error) {
            if (error.message === 'FEEDBACK_RATE_EXCEEDED') {
                setLoading(false);
                setIsErrorAlertOpen(true);
            } else {
                throw error;
            }
        }
    };

    const closeSuccessAlert = () => {
        setIsSuccessAlertOpen(false);
        props.close();
    };

    const closeErrorAlert = () => {
        setIsErrorAlertOpen(false);
    };

    return (
        <>
            <Modal
                close={props.close}
                confirm={confirm}
                confirmLabel={t('sidebar.feedback.button.submit')}
                disabled={!name.trim() || !emailValidator.validate(email) || !message.trim()}
                headline={t('sidebar.feedback.headline')}
                isOpen={props.isOpen}
                loading={loading}
            >
                <Paragraph>{t('sidebar.feedback.p1')}</Paragraph>

                <Form>
                    <FormItem label={t('sidebar.feedback.name')}>
                        <TextField
                            maxLength={128}
                            onChange={setName}
                            value={name}
                        />
                    </FormItem>

                    <FormItem label={t('sidebar.feedback.email')}>
                        <TextField
                            maxLength={128}
                            onChange={setEmail}
                            value={email}
                        />
                    </FormItem>

                    <FormItem label={t('sidebar.feedback.message')}>
                        <TextArea
                            maxLength={4000}
                            onChange={setMessage}
                            value={message}
                        />
                    </FormItem>
                </Form>
            </Modal>

            <Modal
                close={closeSuccessAlert}
                headline={t('sidebar.feedback.success.headline')}
                isAlert
                isOpen={isSuccessAlertOpen}
            >
                <Paragraph>{t('sidebar.feedback.success.p1')}</Paragraph>
            </Modal>

            <Modal
                close={closeErrorAlert}
                headline={t('sidebar.feedback.error.headline')}
                isAlert
                isOpen={isErrorAlertOpen}
            >
                <Paragraph>{t('sidebar.feedback.error.p1')}</Paragraph>
            </Modal>
        </>
    );
};

Feedback.defaultProps = {
    code: null,
};

Feedback.propTypes = {
    close: PropTypes.func.isRequired,
    code: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
};

export default Feedback;
