import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import classnames from 'classnames';
import { setToken, AUTH_TYPE_PLAYER } from 'auth';
import useBecomePlayer from 'hooks/graphql/mutations/becomePlayer';

const PlayerList = (props) => {
    const [scoreModifiers, setScoreModifiers] = useState({});
    const [words, setWords] = useState({});
    const isSuperAdmin = window.location.href.includes('ichdarfalles');
    const client = useApolloClient();
    const becomePlayer = useBecomePlayer();

    useEffect(() => {
        window.addEventListener('scored', handleScored);

        return () => {
            window.removeEventListener('scored', handleScored);
        };
    }, []);

    useEffect(() => {
        setScoreModifiers({});
        setWords(props.players.reduce((result, player) => {
            if (player.tiles.word.length === 0) {
                return { ...result, [player.id]: words[player.id] };
            }

            return { ...result, [player.id]: player.tiles.word };
        }, {}));
    }, [props.game.actions.length]);

    const changePlayerHandler = (id) => async () => {
        if (!isSuperAdmin) {
            return;
        }

        const { data } = await becomePlayer(id);

        setToken(AUTH_TYPE_PLAYER, data.becomePlayer.token);

        client.restartWebSocketConnection();
    };

    const handleScored = (event) => {
        setScoreModifiers((previous) => ({
            ...previous,
            [event.detail]: (previous[event.detail] || 0) + 1,
        }));
    };

    return (
        <div className="player-list">
            {props.players.map((player) => (
                <div
                    key={player.id}
                    className={classnames(
                        'player-list__player',
                        {
                            'player-list__player--ready': props.readyPlayers.some(({ id }) => id === player.id),
                            'player-list__player--super-admin': isSuperAdmin && player.id === props.player?.id,
                        }
                    )}
                    onClick={changePlayerHandler(player.id)}
                    style={{ borderColor: player.color }}
                >
                    <div
                        className={classnames(
                            'player-list__name-wrapper',
                            { 'player-list__name-wrapper--with-word': player.tiles.word.length > 0 }
                        )}
                    >
                        <div className="player-list__name">{player.name}</div>
                        <div className="player-list__word">{words[player.id]}</div>
                    </div>

                    <div
                        className="player-list__score"
                        data-point-to={player.id}
                    >
                        <img
                            alt=""
                            src="/images/star.png"
                        />

                        <span>{player.previousScore + (scoreModifiers[player.id] || 0)}</span>
                    </div>

                    {player.connected || props.game.status === 'DONE' ? (
                        <div className="player-list__check">
                            <i className="fas fa-check" />
                        </div>
                    ) : (
                        <div className="player-list__connection">
                            <i className="fas fa-plug" />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

PlayerList.defaultProps = {
    player: null,
};

PlayerList.propTypes = {
    game: PropTypes.object.isRequired,
    player: PropTypes.object,
    players: PropTypes.array.isRequired,
    readyPlayers: PropTypes.array.isRequired,
};

export default PlayerList;
