import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const colors = [
    '#bf5e41', '#82f1da', '#3ccde8', '#f7b2ea', '#ffe448', '#49c73d', '#6a6c7b',
    '#f79448', '#9f3cc3', '#3e54d4', '#ef3b3b', '#dcc887', '#8fb943', '#dedede',
];

const ColorPicker = (props) => {
    useEffect(() => {
        if (props.blockedColors.includes(props.value)) {
            props.onChange(null);
        }
    }, [props.blockedColors.length]);

    const onChangeHandler = (color) => () => {
        props.onChange(color);
    };

    return (
        <div className="ui-color-picker">
            {colors.map((color) => (
                <div
                    key={color}
                    className={classnames(
                        'ui-color-picker__color',
                        {
                            'ui-color-picker__color--selected': props.value === color,
                            'ui-color-picker__color--blocked': props.blockedColors.includes(color),
                        }
                    )}
                    onClick={onChangeHandler(color)}
                    style={{ backgroundColor: color }}
                >
                    <i className="fas fa-check" />
                </div>
            ))}
        </div>
    );
};

ColorPicker.defaultProps = {
    blockedColors: [],
    value: null,
};

ColorPicker.propTypes = {
    blockedColors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

export default ColorPicker;
