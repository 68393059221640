import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const PlayerName = (props) => (
    <span
        className={classnames(
            'ui-player-name',
            { 'ui-player-name--inline-color': props.inlineColor }
        )}
    >
        <span
            className="ui-player-name__color"
            style={{ backgroundColor: props.color }}
        />

        <span className="ui-player-name__text">{props.name}</span>
    </span>
);

PlayerName.defaultProps = {
    inlineColor: true,
};

PlayerName.propTypes = {
    color: PropTypes.string.isRequired,
    inlineColor: PropTypes.bool,
    name: PropTypes.string.isRequired,
};

export default PlayerName;
