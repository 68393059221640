import { useSubscription } from 'apollo-augmented-hooks';

const subscription = `
    subscription gameOptionsChanged {
        gameOptionsChanged {
            id
            options
        }
    }
`;

export default () => (
    useSubscription(subscription)
);
