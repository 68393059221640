import React, { useState, useMemo, useEffect, useRef } from 'react';
import { animated } from 'react-spring';
import PropTypes from 'prop-types';
import Confetti from 'react-confetti';
import moment from 'moment';
import generateState from 'shared/helpers/generateState';
import states from 'shared/states';
import WordBuilding from './Live/WordBuilding';
import Guessing from './Live/Guessing';
import Scoring from './Live/Scoring';
import Sidebar from './Live/Sidebar';
import Footer from './Live/Footer';
import Result from './Live/Result';

const Live = (props) => {
    const contentRef = useRef();
    const zoomerRef = useRef();
    const innerRef = useRef();
    const [scale, setScale] = useState(1);
    const [isConfettiVisible, setIsConfettiVisible] = useState(false);
    const [submit, setSubmit] = useState({
        handle: () => null,
        isAllowed: false,
    });
    const [, setRandomValue] = useState(null);
    const state = useMemo(() => (
        generateState(props.game)
    ), [props.game.actions.length]);
    const player = state.players.find(({ id }) => id === props.player?.id);

    useEffect(() => {
        window.addEventListener('resize', recalculateZoom);
        window.addEventListener('sidebar-toggle-collapsed', transitionZoom);

        return () => {
            window.removeEventListener('resize', recalculateZoom);
            window.removeEventListener('sidebar-toggle-collapsed', transitionZoom);
        };
    }, []);

    useEffect(() => {
        if (props.game.status === 'DONE') {
            setIsConfettiVisible(true);
        }
    }, [props.game.status]);

    const transitionZoom = () => {
        const startTime = moment();

        const animate = () => {
            if (moment().diff(startTime) >= 1400) {
                return;
            }

            recalculateZoom();
            window.requestAnimationFrame(animate);
        };

        animate();
    };

    const recalculateZoom = () => {
        zoom(innerRef.current);
        setRandomValue(Math.random());
    };

    const zoom = (element) => {
        if (!element) {
            setScale(1);

            return;
        }

        innerRef.current = element;

        const innerHeight = Array.from(element.childNodes).reduce((result, child) => {
            const style = window.getComputedStyle(child);

            return result + child.offsetHeight + parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
        }, 0);

        setScale(
            Math.min(
                (contentRef.current.offsetWidth - 64) / zoomerRef.current.offsetWidth,
                (contentRef.current.offsetHeight - 184) / innerHeight,
                1
            )
        );
    };

    const getReadyPlayers = () => {
        if (state.state === states.WORD_BUILDING.id) {
            return state.players.filter(({ tiles }) => tiles.word.length > 0);
        }

        if (state.state === states.GUESSING.id) {
            return state.players.filter(({ guesses }) => Object.keys(guesses).length === state.players.length - 1);
        }

        if (state.state === states.SCORING.id || state.state === states.SCORING_NEXT_PLAYER.id) {
            return state.players.filter(({ scored }) => !!scored);
        }

        return [];
    };

    const readyPlayers = getReadyPlayers();

    return (
        <animated.div
            className="live"
            style={{ opacity: props.spring.opacity }}
        >
            <Sidebar
                game={props.game}
                player={player}
                readyPlayers={readyPlayers}
                state={state}
            />

            <div
                ref={contentRef}
                className="live__content"
            >
                <div className="live__content-wrapper">
                    <div
                        ref={zoomerRef}
                        className="live__zoomer"
                        style={{ transform: `scale(${scale})` }}
                    >
                        {state.state === states.WORD_BUILDING.id && (
                            <WordBuilding
                                game={props.game}
                                player={player}
                                scale={scale}
                                setSubmit={setSubmit}
                                state={state}
                                zoom={zoom}
                            />
                        )}

                        {state.state === states.GUESSING.id && (
                            <Guessing
                                game={props.game}
                                player={player}
                                scale={scale}
                                setSubmit={setSubmit}
                                state={state}
                                zoom={zoom}
                            />
                        )}

                        {(state.state === states.SCORING.id || state.state === states.SCORING_NEXT_PLAYER.id) && (
                            <Scoring
                                game={props.game}
                                player={player}
                                scale={scale}
                                setSubmit={setSubmit}
                                state={state}
                                zoom={zoom}
                            />
                        )}

                        {state.state === states.GAME_END.id && (
                            <Result
                                game={props.game}
                                player={player}
                                scale={scale}
                                state={state}
                                zoom={zoom}
                            />
                        )}
                    </div>

                    {isConfettiVisible && (
                        <Confetti
                            friction={0.9999}
                            gravity={0.25}
                            height={contentRef.current.offsetHeight}
                            numberOfPieces={400}
                            recycle={false}
                            tweenDuration={5000}
                            width={contentRef.current.offsetWidth}
                        />
                    )}
                </div>

                <Footer
                    pendingPlayers={state.players.filter(({ id }) => !readyPlayers.some((readyPlayer) => readyPlayer.id === id))}
                    player={player}
                    state={state}
                    submit={submit}
                />
            </div>
        </animated.div>
    );
};

Live.defaultProps = {
    player: null,
};

Live.propTypes = {
    game: PropTypes.object.isRequired,
    player: PropTypes.object,
    spring: PropTypes.object.isRequired,
};

export default Live;
