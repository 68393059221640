import { useQuery } from 'apollo-augmented-hooks';
import { GAME } from '../fragments/Game';

const query = `
    query game($code: String!) {
        game(code: $code) {
            ${GAME}
        }
        player {
            id
            game {
                id
            }
        }
    }
`;

export default (code) => (
    useQuery(query, {
        variables: { code },
    })
);
