import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Language = (props) => (
    <img
        alt={props.language}
        className={classnames(
            'ui-language',
            { 'ui-language--selected': props.selected }
        )}
        onClick={props.onClick}
        src={`/images/${props.language}.png`}
    />
);

Language.defaultProps = {
    onClick: null,
    selected: false,
};

Language.propTypes = {
    language: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
};

export default Language;
