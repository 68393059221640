import { useMutation } from 'apollo-augmented-hooks';

const mutation = `
    mutation createDummyPlayer {
        createDummyPlayer {
            id
            name
            color
            isAdmin
            connected
            game {
                id
            }
        }
    }
`;

export default () => {
    const mutate = useMutation(mutation);

    return (input) => (
        mutate({
            input,
            modifiers: [{
                cacheObject: (item) => item.game,
                fields: {
                    players: ({ includeIf }) => (
                        includeIf(true)
                    ),
                },
            }],
        })
    );
};
