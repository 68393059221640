import { useSubscription } from 'apollo-augmented-hooks';

const subscription = `
    subscription gameJoined {
        gameJoined {
            id
            players {
                id
                name
                color
                isAdmin
                connected
            }
        }
    }
`;

export default () => (
    useSubscription(subscription)
);
