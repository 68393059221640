const traverseObject = (object, attributes = []) => {
    const nextAttributes = [...attributes];
    const attribute = nextAttributes.shift();

    return attribute ? traverseObject(object?.[attribute], nextAttributes) : object;
};

export const arrayToggle = (array, value, attributeString = null) => {
    const clone = [...array];
    const attributes = attributeString?.split('.');
    const index = clone.findIndex((item) => (
        traverseObject(item, attributes) === traverseObject(value, attributes)
    ));

    if (index >= 0) {
        clone.splice(index, 1);
    } else {
        clone.push(value);
    }

    return clone;
};
