import { useState } from 'react';
import { useSpring, useSpringRef, useSprings, useChain } from 'react-spring';

export default (guesses, scale) => {
    const [animationDone, setAnimationDone] = useState(false);
    const playerSpringRef = useSpringRef();
    const playerSpring = useSpring({
        ref: playerSpringRef,
        from: {
            y: -100 / scale,
        },
        to: {
            y: 0,
        },
    });
    const guessSpringRef = useSpringRef();
    const guessSpring = useSprings(guesses.length, guesses.map((guess, index) => ({
        ref: guessSpringRef,
        delay: index * 50,
        from: {
            y: 100 / scale,
        },
        to: {
            y: 0,
        },
        onRest: () => {
            setAnimationDone(true);
        },
    })));

    useChain([playerSpringRef, guessSpringRef], [0, 0.5]);

    return {
        anim: {
            player: playerSpring,
            guesses: guessSpring,
        },
        done: animationDone,
    };
};
