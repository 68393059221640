import { useMutation } from 'apollo-augmented-hooks';

const mutation = `
    mutation createFeedback($input: CreateFeedbackInput!) {
        createFeedback(input: $input)
    }
`;

export default () => {
    const mutate = useMutation(mutation);

    return (input) => (
        mutate({ input })
    );
};
