import React from 'react';
import PropTypes from 'prop-types';
import assets from 'shared/assets';
import options from 'shared/options';
import useTranslate from 'hooks/useTranslate';
import useChangeGameOptions from 'hooks/graphql/mutations/changeGameOptions';
import Button from 'atoms/Button';
import NumberInput from 'atoms/NumberInput';
import CheckboxGroup from 'atoms/CheckboxGroup';
import CopyableLink from 'atoms/CopyableLink';
import ViewButton from 'atoms/ViewButton';
import Language from 'atoms/Language';
import Form, { FormItem } from 'molecules/Form';

const Admin = (props) => {
    const t = useTranslate();
    const changeGameOptions = useChangeGameOptions(props.game.id);

    const changeGameOptionsHandler = (key) => async (value) => {
        await changeGameOptions({
            options: {
                ...props.game.options,
                [key]: value,
            },
        });
    };

    return (
        <div className="lobby-admin">
            <Form>
                <FormItem label={t('lobby.link')}>
                    <CopyableLink>{window.location.href}</CopyableLink>
                </FormItem>

                <FormItem label={t('lobby.language')}>
                    <Language
                        language={props.game.language}
                        selected
                    />
                </FormItem>

                {Object.entries(options).map(([key, option]) => (
                    <FormItem
                        key={key}
                        label={t(option.label)}
                    >
                        {option.type === 'number' && (
                            <NumberInput
                                max={option.max}
                                min={option.min}
                                onChange={changeGameOptionsHandler(key)}
                                value={parseInt(props.game.options[key], 10)}
                            />
                        )}

                        {option.type === 'checkbox' && (
                            <CheckboxGroup
                                items={option.values.map((item) => ({
                                    ...item,
                                    label: (
                                        <>
                                            {t(item.label)}

                                            <span className="lobby__category-count">
                                                ({assets.categories[props.game.language][item.value].length} Kategorien)
                                            </span>
                                        </>
                                    ),
                                    action: (
                                        <ViewButton onClick={props.openCardSetHandler(item.value)} />
                                    ),
                                }))}
                                onChange={changeGameOptionsHandler(key)}
                                value={props.game.options[key]}
                            />
                        )}
                    </FormItem>
                ))}
            </Form>

            <Button
                disabled={props.game.players.length < 3 || props.game.options.cardSets.length === 0}
                label={t('lobby.button.start')}
                loading={props.loading}
                onClick={props.startGame}
            />
        </div>
    );
};

Admin.propTypes = {
    loading: PropTypes.bool.isRequired,
    game: PropTypes.object.isRequired,
    openCardSetHandler: PropTypes.func.isRequired,
    startGame: PropTypes.func.isRequired,
};

export default Admin;
