export const GAME = `
    id
    code
    options
    status
    language
    players {
        id
        name
        color
        isAdmin
        connected
    }
    actions {
        id
        type
        payload
        randomSeed
        createdAt
        player {
            id
        }
    }
`;
