import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { animated, useTransition } from 'react-spring';

const CopyableLink = (props) => {
    const textRef = useRef();
    const [copied, setCopied] = useState(false);
    const transitions = useTransition(copied, {
        from: { opacity: 0, y: -6 },
        enter: { opacity: 1, y: 0 },
        leave: { opacity: 0, y: 6 },
    });

    useEffect(() => {
        if (copied) {
            window.setTimeout(() => setCopied(false), 2000);
        }
    }, [copied]);

    const copyLink = () => {
        const selection = window.getSelection();
        const range = document.createRange();

        range.selectNodeContents(textRef.current);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        selection.removeAllRanges();
        setCopied(true);
    };

    return (
        <div className="ui-copyable-link">
            <div
                className="ui-copyable-link__copy"
                onClick={copyLink}
            >
                {transitions((styles, item) => (
                    item ? (
                        <animated.i
                            className="fas fa-check"
                            style={styles}
                        />
                    ) : (
                        <animated.i
                            className="fas fa-copy"
                            style={styles}
                        />
                    )
                ))}
            </div>

            <div
                ref={textRef}
                className="ui-copyable-link__text"
            >
                {props.children}
            </div>
        </div>
    );
};

CopyableLink.propTypes = {
    children: PropTypes.string.isRequired,
};

export default CopyableLink;
