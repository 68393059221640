import ROUND_PREPARATION from './states/ROUND_PREPARATION';
import WORD_BUILDING from './states/WORD_BUILDING';
import GUESSING from './states/GUESSING';
import SCORING from './states/SCORING';
import SCORING_NEXT_PLAYER from './states/SCORING_NEXT_PLAYER';
import GAME_END from './states/GAME_END';

export default {
    ROUND_PREPARATION,
    WORD_BUILDING,
    GUESSING,
    SCORING,
    SCORING_NEXT_PLAYER,
    GAME_END,
    0: ROUND_PREPARATION,
    1: WORD_BUILDING,
    2: GUESSING,
    3: SCORING,
    4: SCORING_NEXT_PLAYER,
    5: GAME_END,
};
