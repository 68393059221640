import React from 'react';

const Loader = () => (
    <div className="ui-loader">
        <div />
        <div />
        <div />
        <div />
    </div>
);

export default Loader;
