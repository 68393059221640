import React, { useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import classnames from 'classnames';
import { animated } from 'react-spring';
import usePushActions from 'hooks/graphql/mutations/pushActions';
import useGuessingAnimations from 'hooks/animations/useGuessing';
import actions from 'shared/actions';
import randomizer from 'shared/helpers/randomizer';
import Draggable from 'Draggable';
import Category from 'atoms/Category';
import Letter from 'atoms/Letter';
import Word from 'molecules/Word';

const Guessing = (props) => {
    const guessablePlayers = props.state.players.filter(({ id }) => id !== props.player?.id);
    const contentRef = useRef();
    const [dragCategory, setDragCategory] = useState(null);
    const [originalX, setOriginalX] = useState(0);
    const [originalY, setOriginalY] = useState(0);
    const [originalRect, setOriginalRect] = useState(null);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [dropping, setDropping] = useState(false);
    const [isAlreadyGuessed, setIsAlreadyGuessed] = useState(false);
    const [isNewGuess, setIsNewGuess] = useState(false);
    const { shuffle } = randomizer();
    const categories = useMemo(() => (
        shuffle([
            ...props.state.players.filter(({ id }) => id !== props.player?.id).map(({ category }) => category),
            ...props.state.game.dummyCategories,
        ])
    ), [props.player?.id]);
    const [guesses, setGuesses] = useState(props.player?.guesses || {});
    const pushActions = usePushActions();
    const { anim } = useGuessingAnimations(guessablePlayers, categories, props.scale);

    useEffect(() => {
        props.zoom(contentRef.current);

        return () => {
            props.zoom(null);
        };
    }, []);

    useEffect(() => {
        props.setSubmit({
            handle: submit,
            isAllowed: Object.keys(guesses).length === guessablePlayers.length,
        });
    }, [JSON.stringify(guesses)]);

    useEffect(() => {
        setGuesses(props.player?.guesses || {});
    }, [props.player?.id]);

    const submit = async () => {
        await pushActions({
            actions: [{
                id: uuid(),
                type: actions.GUESS_WORDS.id,
                payload: {
                    guesses,
                },
            }],
        }, props.game.actions, props.player);
    };

    const onDragStartHandler = (category, alreadyGuessed = false) => (event) => {
        const rect = event.currentTarget.getBoundingClientRect();

        setIsAlreadyGuessed(alreadyGuessed);
        setOriginalRect(rect);
        setOriginalX(event.touches ? event.touches[0].clientX : event.clientX);
        setOriginalY(event.touches ? event.touches[0].clientY : event.clientY);
        setDragCategory(category);
    };

    const onDrag = (event) => {
        const clientX = event.touches ? event.touches[0].clientX : event.clientX;
        const clientY = event.touches ? event.touches[0].clientY : event.clientY;

        setOffsetX(clientX - originalX);
        setOffsetY(clientY - originalY);
    };

    const onDragEnd = (event, lastDragEvent) => {
        const clientX = lastDragEvent ? lastDragEvent.touches[0].clientX : event.clientX;
        const clientY = lastDragEvent ? lastDragEvent.touches[0].clientY : event.clientY;
        const dropItems = Array.from(document.querySelectorAll('.guessing__player'));
        const overlappingDropItem = dropItems.find((dropItem) => {
            const dropItemRect = dropItem.getBoundingClientRect();

            return (
                clientX <= dropItemRect.right
                && clientX >= dropItemRect.left
                && clientY <= dropItemRect.bottom
                && clientY >= dropItemRect.top
            );
        });

        setDropping(true);

        if (overlappingDropItem) {
            const overlappingDropItemRect = overlappingDropItem.querySelector('.guessing__guess-inner').getBoundingClientRect();

            setIsNewGuess(!isAlreadyGuessed);
            setOffsetX(overlappingDropItemRect.left - originalRect.left + (overlappingDropItemRect.width / 2) - (originalRect.width / 2));
            setOffsetY(overlappingDropItemRect.top - originalRect.top + (overlappingDropItemRect.height / 2) - (originalRect.height / 2));
        } else {
            setIsNewGuess(false);
            setOffsetX(0);
            setOffsetY(0);
        }

        window.setTimeout(() => {
            if (overlappingDropItem) {
                setGuesses((previous) => {
                    const next = Object.entries(previous).reduce((result, [playerId, category]) => {
                        if (category === dragCategory) {
                            return result;
                        }

                        return { ...result, [playerId]: category };
                    }, {});

                    return {
                        ...next,
                        [overlappingDropItem.dataset.player]: dragCategory,
                    };
                });
            }

            setDropping(false);
            setDragCategory(null);
            setOffsetX(0);
            setOffsetY(0);
            setOriginalX(0);
            setOriginalY(0);
            setOriginalRect(null);
            setIsAlreadyGuessed(false);
            setIsNewGuess(false);
        }, 300);
    };

    const getPlayersPerRow = () => {
        const windowRatio = window.innerHeight / window.innerWidth;

        if (windowRatio > 1) {
            return 2;
        }

        if (windowRatio > 0.8) {
            return 3;
        }

        return 4;
    };

    const renderPlayers = () => (
        <div
            className="guessing__players"
            style={{ width: Math.min(getPlayersPerRow(), guessablePlayers.length) * 428 }}
        >
            {anim.players.map((styles, index) => (
                <animated.div
                    key={guessablePlayers[index].id}
                    className="guessing__player"
                    data-player={guessablePlayers[index].id}
                    style={{ transform: styles.y.to((y) => `translateY(${y}vh)`) }}
                >
                    <div className="guessing__player-name">
                        {guessablePlayers[index].name}
                    </div>

                    <Word
                        color={guessablePlayers[index].color}
                        scale={props.scale}
                        small
                    >
                        {guessablePlayers[index].tiles.word.map((letter) => (
                            <Letter
                                key={letter}
                                small
                            >
                                {letter}
                            </Letter>
                        ))}
                    </Word>

                    <div className="guessing__guess">
                        <div className="guessing__guess-inner">
                            {guesses[guessablePlayers[index].id] && (
                                <Draggable
                                    key={guesses[guessablePlayers[index].id]}
                                    disabled={!props.player || Object.keys(props.player?.guesses).length > 0 || dropping}
                                    onDrag={onDrag}
                                    onDragEnd={onDragEnd}
                                    onDragStart={onDragStartHandler(guesses[guessablePlayers[index].id], true)}
                                >
                                    <Category
                                        name={guesses[guessablePlayers[index].id]}
                                        style={{
                                            opacity: dragCategory === guesses[guessablePlayers[index].id] ? 0 : 1,
                                        }}
                                    />
                                </Draggable>
                            )}
                        </div>
                    </div>
                </animated.div>
            ))}
        </div>
    );

    const renderDragItem = () => {
        const zoomerRect = contentRef.current.closest('[style^="transform"]').getBoundingClientRect();

        return (
            <Category
                name={dragCategory}
                style={{
                    cursor: 'move',
                    left: (originalRect.left - zoomerRect.left) / props.scale,
                    position: 'fixed',
                    top: (originalRect.top - zoomerRect.top) / props.scale,
                    transform: `translate(${offsetX / props.scale}px, ${offsetY / props.scale}px) scale(${dropping && isNewGuess ? 0.75 : 1})`,
                    transition: dropping ? 'transform 300ms' : null,
                    zIndex: 2,
                }}
            />
        );
    };

    const renderCategories = () => (
        <div className="guessing__categories">
            {anim.categories.map((styles, index) => (
                <animated.div
                    key={categories[index]}
                    style={{ transform: styles.y.to((y) => `translateY(${y}vh)`) }}
                >
                    <Draggable
                        disabled={
                            !props.player
                            || Object.keys(props.player?.guesses).length > 0
                            || dropping
                            || Object.values(guesses).includes(categories[index])
                        }
                        onDrag={onDrag}
                        onDragEnd={onDragEnd}
                        onDragStart={onDragStartHandler(categories[index])}
                    >
                        <Category
                            name={categories[index]}
                            style={{
                                opacity: dragCategory === categories[index] || Object.values(guesses).includes(categories[index]) ? 0.6 : 1,
                            }}
                        />
                    </Draggable>
                </animated.div>
            ))}

            <div
                className={classnames(
                    'guessing__drag-item',
                    { 'guessing__drag-item--already-guessed': isAlreadyGuessed }
                )}
            >
                {dragCategory !== null && renderDragItem()}
            </div>
        </div>
    );

    return (
        <div
            ref={contentRef}
            className="guessing"
        >
            {renderPlayers()}
            {renderCategories()}
        </div>
    );
};

Guessing.defaultProps = {
    player: null,
};

Guessing.propTypes = {
    game: PropTypes.object.isRequired,
    player: PropTypes.object,
    scale: PropTypes.number.isRequired,
    setSubmit: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired,
    zoom: PropTypes.func.isRequired,
};

export default Guessing;
