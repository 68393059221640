import React from 'react';
import PropTypes from 'prop-types';

const ViewButton = (props) => (
    <span
        className="ui-view-button"
        onClick={props.onClick}
    >
        <i className="fas fa-eye" />
    </span>
);

ViewButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default ViewButton;
