import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { animated, useTransition, useSpring } from 'react-spring';
import { useLocalStorage } from '@rehooks/local-storage';
import useTranslate from 'hooks/useTranslate';
import useToggle from 'hooks/useToggle';
import useUndoLastAction from 'hooks/graphql/mutations/undoLastAction';
import states from 'shared/states';
import Feedback from 'Feedback';
import Logo from 'atoms/Logo';
import Paragraph from 'atoms/Paragraph';
import IconButton from 'atoms/IconButton';
import Button from 'atoms/Button';
import Modal from 'molecules/Modal';
import PlayerList from './Sidebar/PlayerList';

const Sidebar = (props) => {
    const t = useTranslate();
    const [isHelpOpen, openHelp, closeHelp] = useToggle(false);
    const [isFeedbackOpen, openFeedback, closeFeedback] = useToggle(false);
    const [isCollapsed, setCollapsed] = useLocalStorage('sidebar-collapsed', false);
    const isSuperAdmin = window.location.href.includes('ichdarfalles');
    const undoLastAction = useUndoLastAction();
    const transitions = useTransition(isCollapsed, {
        initial: { x: '0%', opacity: 1 },
        from: { x: '-100%', opacity: 0 },
        enter: { x: '0%', opacity: 1 },
        leave: { x: '-100%', opacity: 0 },
        trail: 700,
    });
    const [sidebarStyles, sidebarApi] = useSpring(() => ({
        from: {
            width: isCollapsed ? 64 : 360,
        },
    }));
    const players = props.state.players.map((player) => ({
        ...player,
        ...props.game.players.find(({ id }) => id === player.id),
    }));

    const toggleCollapsed = () => {
        setCollapsed(!isCollapsed);

        window.dispatchEvent(new CustomEvent('sidebar-toggle-collapsed'));

        // XXX Super ugly hack because useSpring's chains aren't working properly
        sidebarApi.start({ to: { width: isCollapsed ? 64 : 360 } });
        sidebarApi.start({ to: { width: 0 } });

        window.setTimeout(() => {
            sidebarApi.start({ to: { width: !isCollapsed ? 64 : 360 } });
        }, 700);
    };

    const undo = async () => {
        await undoLastAction();
    };

    const renderHelpModal = () => {
        if (!props.player) {
            return null;
        }

        if (props.state.state === states.WORD_BUILDING.id) {
            return (
                <Modal
                    close={closeHelp}
                    headline={t('sidebar.help.wordBuilding.headline')}
                    isOpen={isHelpOpen}
                >
                    <Paragraph>{t('sidebar.help.wordBuilding.p1', { category: <em>{props.player.category}</em> })}</Paragraph>
                    <Paragraph>{t('sidebar.help.wordBuilding.p2')}</Paragraph>
                    <Paragraph>{t('sidebar.help.wordBuilding.p3', { shuffle: <i className="fas fa-random" /> })}</Paragraph>
                    <Paragraph>{t('sidebar.help.wordBuilding.p4')}</Paragraph>
                    <Paragraph>{t('sidebar.help.wordBuilding.p5')}</Paragraph>
                </Modal>
            );
        }

        if (props.state.state === states.GUESSING.id) {
            return (
                <Modal
                    close={closeHelp}
                    headline={t('sidebar.help.guessing.headline')}
                    isOpen={isHelpOpen}
                >
                    <Paragraph>{t('sidebar.help.guessing.p1')}</Paragraph>
                    <Paragraph>{t('sidebar.help.guessing.p2')}</Paragraph>
                </Modal>
            );
        }

        if (props.state.state === states.SCORING.id || props.state.state === states.SCORING_NEXT_PLAYER.id) {
            return (
                <Modal
                    close={closeHelp}
                    headline={t('sidebar.help.scoring.headline')}
                    isOpen={isHelpOpen}
                >
                    <Paragraph>{t('sidebar.help.scoring.p1')}</Paragraph>
                    <Paragraph>{t('sidebar.help.scoring.p2')}</Paragraph>
                    <Paragraph>{t('sidebar.help.scoring.p3')}</Paragraph>
                </Modal>
            );
        }

        return null;
    };

    const helpModal = renderHelpModal();

    return (
        <animated.div
            className="sidebar"
            style={sidebarStyles}
        >
            {transitions((styles, item) => (
                item ? (
                    <animated.div
                        className="sidebar-collapsed"
                        style={styles}
                    >
                        <div className="sidebar-collapsed__content">
                            <div className="sidebar-collapsed__round">
                                {props.state.game.round}/{props.game.options.rounds}
                            </div>

                            <PlayerList
                                game={props.game}
                                player={props.player}
                                players={players}
                                readyPlayers={props.readyPlayers}
                            />
                        </div>
                    </animated.div>
                ) : (
                    <animated.div
                        className="sidebar-expanded"
                        style={styles}
                    >
                        <div className="sidebar-expanded__content">
                            <div className="sidebar-expanded__round">
                                {t('sidebar.round')} <span>{props.state.game.round}/{props.game.options.rounds}</span>
                            </div>

                            <PlayerList
                                game={props.game}
                                player={props.player}
                                players={players}
                                readyPlayers={props.readyPlayers}
                            />

                            {helpModal && props.player && (
                                <IconButton
                                    icon="question"
                                    label={t('sidebar.help')}
                                    onClick={openHelp}
                                />
                            )}

                            <IconButton
                                icon="comment"
                                label={t('sidebar.feedback')}
                                onClick={openFeedback}
                            />

                            {isSuperAdmin && (
                                <Button
                                    label={t('sidebar.undo')}
                                    onClick={undo}
                                />
                            )}
                        </div>

                        <Logo small />
                    </animated.div>
                )
            ))}

            <div
                className="sidebar__collapse"
                onClick={toggleCollapsed}
            >
                <i
                    className={classnames(
                        'fas',
                        {
                            'fa-caret-right': isCollapsed,
                            'fa-caret-left': !isCollapsed,
                        }
                    )}
                />
            </div>

            {helpModal}

            <Feedback
                close={closeFeedback}
                code={props.game.code}
                isOpen={isFeedbackOpen}
            />
        </animated.div>
    );
};

Sidebar.defaultProps = {
    player: null,
};

Sidebar.propTypes = {
    game: PropTypes.object.isRequired,
    player: PropTypes.object,
    readyPlayers: PropTypes.array.isRequired,
    state: PropTypes.object.isRequired,
};

export default Sidebar;
