import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { animated } from 'react-spring';
import shuffle from 'knuth-shuffle-seeded';
import usePushActions from 'hooks/graphql/mutations/pushActions';
import useWordBuildingAnimations from 'hooks/animations/useWordBuilding';
import actions from 'shared/actions';
import Sortable from 'Sortable';
import Category from 'atoms/Category';
import Letter from 'atoms/Letter';
import Loader from 'atoms/Loader';
import Word from 'molecules/Word';

const WordBuilding = (props) => {
    const contentRef = useRef();
    const [tiles, setTiles] = useState(props.player?.tiles || { word: [], pool: [] });
    const pushActions = usePushActions();
    const { anim, done } = useWordBuildingAnimations(tiles.pool, props.scale);

    useEffect(() => {
        props.zoom(contentRef.current);

        return () => {
            props.zoom(null);
        };
    }, []);

    useEffect(() => {
        props.setSubmit({
            handle: submit,
            isAllowed: tiles.word.filter((letter) => letter !== ' ').length > 0,
        });
    }, [JSON.stringify(tiles.word)]);

    useEffect(() => {
        setTiles(props.player?.tiles || { word: [], pool: [] });
    }, [props.player?.id]);

    const onSortStop = ({ from, to }) => {
        setTiles((previous) => {
            const next = {
                pool: [...previous.pool],
                word: [...previous.word],
            };

            next[to.name].splice(to.index, 0, next[from.name].splice(from.index, 1)[0]);

            return next;
        });
    };

    const shufflePool = () => {
        setTiles((previous) => {
            const poolWithoutSpaces = previous.pool.filter((letter) => letter !== ' ');
            const pool = [
                ...shuffle(poolWithoutSpaces),
                ...Array(previous.pool.length - poolWithoutSpaces.length).fill(' '),
            ];

            return {
                ...previous,
                pool,
            };
        });
    };

    const submit = async () => {
        await pushActions({
            actions: [{
                id: uuid(),
                type: actions.SUBMIT_WORD.id,
                payload: {
                    tiles,
                },
            }],
        }, props.game.actions, props.player);
    };

    return (
        <div
            ref={contentRef}
            className="word-building"
        >
            {props.player ? (
                <>
                    <animated.div
                        className="word-building__category"
                        style={{ transform: anim.category.y.to((y) => `translateY(${y}vh)`) }}
                    >
                        <Category name={props.player.category} />
                    </animated.div>

                    <animated.div
                        className="word-building__shuffle"
                        onClick={shufflePool}
                        style={anim.shuffle}
                    >
                        <i className="fas fa-random" />
                    </animated.div>

                    <Sortable
                        className="word-building__pool"
                        disabled={props.player.tiles.word.length > 0}
                        group="tiles"
                        margin={1}
                        name="pool"
                        onChange={onSortStop}
                        scale={props.scale}
                    >
                        {anim.tiles.map((styles, index) => (
                            <animated.div
                                key={index}
                                style={{ transform: styles.xy.to((x, y) => `translate(${x}vw, ${y}vh)`) }}
                            >
                                <Letter>{tiles.pool[index]}</Letter>
                            </animated.div>
                        ))}
                    </Sortable>

                    <animated.div style={done ? null : { transform: anim.word.y.to((y) => `translateY(${y}vh)`) }}>
                        <Word
                            color={props.player.color}
                            disabled={props.player.tiles.word.length > 0}
                            name="word"
                            onChange={onSortStop}
                            scale={props.scale}
                        >
                            {tiles.word.map((tile, index) => (
                                <Letter key={index}>{tile}</Letter>
                            ))}
                        </Word>
                    </animated.div>
                </>
            ) : (
                <animated.div
                    className="word-building__spectator"
                    style={{ transform: anim.category.y.to((y) => `translateY(${y}vh)`) }}
                >
                    Die Spieler sind gerade dabei, Wörter zu erfinden... <br /> Gleich geht&apos;s weiter!

                    <Loader />
                </animated.div>
            )}
        </div>
    );
};

WordBuilding.defaultProps = {
    player: null,
};

WordBuilding.propTypes = {
    game: PropTypes.object.isRequired,
    player: PropTypes.object,
    scale: PropTypes.number.isRequired,
    setSubmit: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired,
    zoom: PropTypes.func.isRequired,
};

export default WordBuilding;
