import React, { useContext } from 'react';
import reactStringReplace from 'react-string-replace';
import parseHtml from 'html-react-parser';
import LocaleContext from 'contexts/locale';
import de from 'translations/de';
import en from 'translations/en';

const translationMap = { de, en };

export default (localeOverride) => {
    const locale = localeOverride || useContext(LocaleContext);

    return (translationKey, params = {}) => {
        const translation = translationMap[locale][translationKey];

        if (!translation) {
            throw new Error(`Invalid translation key: ${translationKey}`);
        }

        const string = parseHtml(translation
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/{br}/g, '<br />')
            .replace(/{em}(.+?){\/em}/g, '<em>$1</em>'));

        return Object.entries(params).reduce((result, [key, value]) => (
            reactStringReplace(
                result,
                `{${key}}`,
                /* eslint-disable-next-line react/jsx-filename-extension */
                () => <React.Fragment key={key}>{value}</React.Fragment>
            )
        ), string);
    };
};
