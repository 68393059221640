import State from '../classes/State';

export default class extends State {
    static get id() {
        return 0;
    }

    static performAutomatically() {
        const actions = require('../actions').default;

        return actions.PREPARE_ROUND;
    }
}
