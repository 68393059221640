export default {
    read: (key) => {
        try {
            return window.localStorage.getItem(key);
        } catch (error) {
            // localStorage is not supported when browsing privately
            return null;
        }
    },
    write: (key, value) => {
        try {
            window.localStorage.setItem(key, value);
        } catch (error) {
            // localStorage is not supported when browsing privately
        }
    },
    remove: (key) => {
        try {
            window.localStorage.removeItem(key);
        } catch (error) {
            // localStorage is not supported when browsing privately
        }
    },
};
