import React from 'react';
import PropTypes from 'prop-types';

const Paragraph = (props) => (
    <div className="ui-paragraph">
        {props.children}
    </div>
);

Paragraph.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Paragraph;
