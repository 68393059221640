import { ApolloClient, InMemoryCache, HttpLink, from, split, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { initAugmentedHooks, setGlobalContextHook } from 'apollo-augmented-hooks';
import { getToken, AUTH_TYPE_NONE, AUTH_TYPE_PLAYER } from 'auth';
import useGlobalApolloContext from 'hooks/useGlobalApolloContext';

const cache = new InMemoryCache();

const webSocketLink = new WebSocketLink({
    uri: window.appConfig.apiUrlWs,
    options: {
        reconnect: true,
        connectionParams: () => {
            const authToken = getToken(AUTH_TYPE_PLAYER);

            return {
                authorization: authToken ? `Bearer ${authToken}` : undefined,
                code: window.location.pathname.replace(/\//g, ''),
            };
        },
    },
});

const toggleConnectionStatus = (connected) => {
    const data = cache.readQuery({
        query: gql`
            query {
                player {
                    id
                }
            }
        `,
    });

    if (!data?.player) {
        return;
    }

    cache.modify({
        id: cache.identify(data.player),
        fields: {
            connected: () => connected,
        },
    });
};

webSocketLink.subscriptionClient.onConnected(() => {
    toggleConnectionStatus(true);
});

webSocketLink.subscriptionClient.onReconnected(() => {
    toggleConnectionStatus(true);
});

webSocketLink.subscriptionClient.onDisconnected(() => {
    toggleConnectionStatus(false);
});

const client = new ApolloClient({
    link: split(
        ({ query }) => {
            const { kind, operation } = getMainDefinition(query);

            return kind === 'OperationDefinition' && operation === 'subscription';
        },
        webSocketLink,
        from([
            onError(({ graphQLErrors, networkError }) => {
                let error = null;

                if (graphQLErrors) {
                    graphQLErrors.forEach(({ name, message, locations, path }) => {
                        if (name) {
                            error = `[Custom error]: Name: ${name}, Message: ${message}`;
                        } else {
                            error = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`;
                        }
                    });
                } else if (networkError) {
                    // eslint-disable-next-line no-console
                    error = `[Network error]: ${networkError}`;
                }

                // eslint-disable-next-line no-console
                console.error(error);
            }),
            setContext((_, context) => {
                const token = context.authType === AUTH_TYPE_NONE
                    ? null
                    : getToken(context.authType);
                const headers = { ...context.headers };

                if (token) {
                    headers.Authorization = `Bearer ${token}`;
                }

                return { ...context, headers };
            }),
            new HttpLink({
                uri: window.appConfig.apiUrl,
                fetch,
            }),
        ]),
    ),
    cache,
});

client.restartWebSocketConnection = () => {
    webSocketLink.subscriptionClient.close(false);
};

initAugmentedHooks(client);
setGlobalContextHook(useGlobalApolloContext);

export default client;
