import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { animated } from 'react-spring';
import { useApolloClient } from '@apollo/client';
import { setToken, AUTH_TYPE_PLAYER } from 'auth';
import useTranslate from 'hooks/useTranslate';
import useCreateConsecutiveGame from 'hooks/graphql/mutations/createConsecutiveGame';
import useResultAnimations from 'hooks/animations/useResult';
import Button from 'atoms/Button';

const Result = (props) => {
    const t = useTranslate();
    const contentRef = useRef();
    const players = [...props.state.players].sort((a, b) => b.score - a.score);
    const createConsecutiveGame = useCreateConsecutiveGame();
    const client = useApolloClient();
    const history = useHistory();
    const { anim } = useResultAnimations(players, props.scale);

    useEffect(() => {
        props.zoom(contentRef.current);

        return () => {
            props.zoom(null);
        };
    }, []);

    const createNewGame = async () => {
        const { data } = await createConsecutiveGame();

        setToken(AUTH_TYPE_PLAYER, data.createConsecutiveGame.token);

        client.restartWebSocketConnection();

        history.push(`/${data.createConsecutiveGame.game.code}`);
    };

    const renderPlayers = () => {
        let placement = 0;

        return anim.players.map((styles, index) => {
            if (players[index - 1]?.score !== players[index].score) {
                placement = index + 1;
            }

            return (
                <animated.div
                    key={players[index].id}
                    className={classnames(
                        'result__player',
                        `result__player--${placement}`
                    )}
                    style={{
                        borderColor: players[index].color,
                        transform: styles.y.to((y) => `translateY(${y}vh)`),
                    }}
                >
                    {players[index].name}

                    <div className="result__player-score">
                        <img
                            alt=""
                            src="/images/star.png"
                        />

                        <span>{players[index].score}</span>
                    </div>

                    <div className="result__player-cup">
                        {placement === 1 && '🥇'}
                        {placement === 2 && '🥈'}
                        {placement === 3 && '🥉'}
                        {placement >= 4 && `${placement}.`}
                    </div>
                </animated.div>
            );
        });
    };

    return (
        <div
            ref={contentRef}
            className="result"
        >
            <animated.div
                className="result__header"
                style={{ transform: anim.text.y.to((y) => `translateY(${y}vh)`) }}
            >
                {t('live.results.headline')}
            </animated.div>

            <div className="result__scores">
                {renderPlayers()}
            </div>

            {props.player?.isAdmin && (
                <animated.div style={{ transform: anim.button.y.to((y) => `translateY(${y}vh)`) }}>
                    <Button
                        label={t('live.results.button.rematch')}
                        onClick={createNewGame}
                    />
                </animated.div>
            )}
        </div>
    );
};

Result.defaultProps = {
    player: null,
};

Result.propTypes = {
    game: PropTypes.object.isRequired,
    player: PropTypes.object,
    scale: PropTypes.number.isRequired,
    state: PropTypes.object.isRequired,
    zoom: PropTypes.func.isRequired,
};

export default Result;
