import React, { useState } from 'react';
import PropTypes from 'prop-types';

const NumberInput = (props) => {
    const [value, setValue] = useState(props.value);

    const onChange = (event) => {
        setValue(event.target.value);
    };

    const onBlur = () => {
        if (Number.isNaN(parseInt(value, 10))) {
            setValue(props.value);
        } else {
            const modifiedValue = Math.max(props.min, Math.min(props.max, value));

            setValue(modifiedValue);
            props.onChange(modifiedValue);
        }
    };

    const up = () => {
        const nextValue = Math.min(props.max, value + 1);

        setValue(nextValue);
        props.onChange(nextValue);
    };

    const down = () => {
        const nextValue = Math.max(props.min, value - 1);

        setValue(nextValue);
        props.onChange(nextValue);
    };

    return (
        <div className="ui-number-input">
            <input
                className="ui-number-input__input"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
            />

            <div
                className="ui-number-input__up"
                onClick={up}
            >
                <i className="fas fa-sort-up" />
            </div>

            <div
                className="ui-number-input__down"
                onClick={down}
            >
                <i className="fas fa-sort-down" />
            </div>
        </div>
    );
};

NumberInput.propTypes = {
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
};

export default NumberInput;
