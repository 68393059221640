import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import assets from 'shared/assets';
import LocaleContext from 'contexts/locale';
import Modal from 'molecules/Modal';

const Feedback = (props) => {
    const locale = useContext(LocaleContext);
    const categories = assets.categories[locale][props.type];
    const cutoffA = Math.floor(categories.length / 3);
    const cutoffB = Math.ceil((categories.length / 3) * 2);
    const leftColumn = categories.slice(0, cutoffA);
    const centerColumn = categories.slice(cutoffA + 1, cutoffB);
    const rightColumn = categories.slice(cutoffB + 1);

    const renderCategory = (category) => (
        <div
            key={category}
            className="catd-set__category"
        >
            {category}
        </div>
    );

    return (
        <Modal
            close={props.close}
            headline={props.label}
            isOpen={props.isOpen}
        >
            <div className="card-set">
                <div className="card-set__column">
                    {leftColumn.map(renderCategory)}
                </div>

                <div className="card-set__column">
                    {centerColumn.map(renderCategory)}
                </div>

                <div className="card-set__column">
                    {rightColumn.map(renderCategory)}
                </div>
            </div>
        </Modal>
    );
};

Feedback.propTypes = {
    close: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default Feedback;
