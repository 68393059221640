import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';
import LocaleContext from 'contexts/locale';
import useGame from 'hooks/graphql/queries/game';
import useGameSubscriptions from 'hooks/graphql/subscriptions/game';
import Loader from 'atoms/Loader';
import Lobby from './Game/Lobby';
import Live from './Game/Live';

const Game = (props) => {
    const history = useHistory();
    const params = useParams();
    const { data, loading, error } = useGame(params.code);
    const transitions = useTransition(data?.game.status, {
        from: { opacity: 1 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    });

    useGameSubscriptions();

    useEffect(() => {
        if (error?.message === 'GAME_DOES_NOT_EXIST') {
            history.replace('/');
        }
    }, [error?.message]);

    if (loading) {
        return (
            <Loader />
        );
    }

    return (
        <LocaleContext.Provider value={data.game.language}>
            {transitions((styles, status) => (
                <animated.div style={styles}>
                    {(status === 'LIVE' || status === 'DONE') && (
                        <Live
                            game={data.game}
                            player={data.player}
                            spring={props.spring}
                        />
                    )}

                    {status === 'PENDING' && (
                        <Lobby
                            game={data.game}
                            player={data.player}
                            spring={props.spring}
                        />
                    )}
                </animated.div>
            ))}
        </LocaleContext.Provider>
    );
};

Game.propTypes = {
    spring: PropTypes.object.isRequired,
};

export default Game;
