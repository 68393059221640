import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { animated } from 'react-spring';
import useTranslate from 'hooks/useTranslate';
import usePushActions from 'hooks/graphql/mutations/pushActions';
import useScoringAnimations from 'hooks/animations/useScoring';
import actions from 'shared/actions';
import states from 'shared/states';
import Letter from 'atoms/Letter';
import Category from 'atoms/Category';
import PlayerName from 'atoms/PlayerName';
import Word from 'molecules/Word';
import PointSet from './Scoring/PointSet';

const Scoring = (props) => {
    const t = useTranslate();
    const contentRef = useRef();
    const currentPlayer = props.state.players.find((player) => (
        player.id === props.state.game.scoringOrder.filter((playerId) => (
            !props.state.players.find(({ id }) => id === playerId).scored
        ))[0]
    ));
    const guesses = props.state.players
        .filter(({ id }) => id !== currentPlayer.id)
        .sort((a, b) => moment(a.guessedAt).diff(b.guessedAt))
        .map((player) => ({
            player,
            category: player.guesses[currentPlayer.id],
        }));
    const [pointSets, setPointSets] = useState([]);
    const pushActions = usePushActions();
    const { anim } = useScoringAnimations(guesses, props.scale);

    useEffect(() => {
        props.zoom(contentRef.current);

        return () => {
            props.zoom(null);
        };
    }, []);

    useEffect(() => {
        props.setSubmit({
            handle: submit,
            isAllowed: props.state.state === states.SCORING_NEXT_PLAYER.id,
            currentPlayer,
        });
    }, [props.state.state, currentPlayer.id]);

    useEffect(() => {
        setPointSets((previous) => {
            const playerIds = props.state.players
                .filter((player) => player.score !== player.previousScore)
                .sort((a, b) => {
                    const diff = (b.id === currentPlayer.id) - (a.id === currentPlayer.id);

                    if (diff !== 0) {
                        return diff;
                    }

                    return guesses.findIndex(({ player }) => player.id === a.id) - guesses.findIndex(({ player }) => player.id === b.id);
                })
                .reduce((result, player) => {
                    const points = player.score - player.previousScore;

                    if (points === 0) {
                        return result;
                    }

                    return [
                        ...result,
                        ...Array(points).fill(null).map(() => player.id),
                    ];
                }, []);

            if (playerIds.length === 0) {
                return previous;
            }

            return [
                ...previous,
                {
                    actionId: props.game.actions[props.game.actions.length - 1].id,
                    playerIds,
                },
            ];
        });
    }, [props.game.actions.length]);

    useEffect(() => {
        if (props.state.state === states.SCORING.id) {
            setPointSets([]);
        }
    }, [props.state.state]);

    const removePointSetHandler = (actionId) => () => {
        setPointSets((previous) => (
            previous.filter((pointSet) => pointSet.actionId !== actionId)
        ));
    };

    const showCardHandler = (playerId) => async () => {
        await pushActions({
            actions: [{
                id: uuid(),
                type: actions.SHOW_CARD.id,
                payload: {
                    playerId,
                },
            }],
        }, props.game.actions, props.player);
    };

    const submit = async () => {
        await pushActions({
            actions: [{
                id: uuid(),
                type: actions.PROCEED_SCORING.id,
                payload: {},
            }],
        }, props.game.actions, props.player);
    };

    return (
        <div
            key={currentPlayer.id}
            ref={contentRef}
            className="scoring"
        >
            <div className="scoring__player">
                <animated.div style={{ transform: anim.player.y.to((y) => `translateY(${y}vh)`) }}>
                    <div className="scoring__player-name">
                        {currentPlayer.name}
                    </div>

                    <div className="scoring__word">
                        <Word
                            color={currentPlayer.color}
                            scale={props.scale}
                        >
                            {currentPlayer.tiles.word.map((tile, index) => (
                                <Letter key={index}>{tile}</Letter>
                            ))}
                        </Word>
                    </div>

                    <div className="scoring__solution">
                        <div className="scoring__solution-text">
                            {t('live.scoring.solution')}
                        </div>

                        <div data-point-from={currentPlayer.id}>
                            <Category
                                flipped={!currentPlayer.shownSolution}
                                name={currentPlayer.category}
                                onClick={
                                    !currentPlayer.shownSolution && props.player?.id === currentPlayer.id
                                        ? showCardHandler(currentPlayer.id)
                                        : null
                                }
                            />
                        </div>
                    </div>
                </animated.div>

                <div className="scoring__guesses">
                    {anim.guesses.map((styles, index) => (
                        <animated.div
                            key={guesses[index].player.id}
                            className="scoring__guess"
                            style={{ transform: styles.y.to((y) => `translateY(${y}vh)`) }}
                        >
                            <div className="scoring__guess-name">
                                <PlayerName
                                    color={guesses[index].player.color}
                                    inlineColor={false}
                                    name={guesses[index].player.name}
                                />
                            </div>

                            <div data-point-from={guesses[index].player.id}>
                                <Category
                                    flipped={!currentPlayer.shownGuesses.includes(guesses[index].player.id)}
                                    name={guesses[index].category}
                                    onClick={
                                        (
                                            !currentPlayer.shownGuesses.includes(guesses[index].player.id)
                                            && props.player?.id === currentPlayer.id
                                        )
                                            ? showCardHandler(guesses[index].player.id)
                                            : null
                                    }
                                />
                            </div>
                        </animated.div>
                    ))}
                </div>
            </div>

            {pointSets.map((pointSet) => (
                <PointSet
                    key={pointSet.actionId}
                    affectScore={pointSet.actionId === props.game.actions[props.game.actions.length - 1].id}
                    playerIds={pointSet.playerIds}
                    removePointSet={removePointSetHandler(pointSet.actionId)}
                />
            ))}
        </div>
    );
};

Scoring.defaultProps = {
    player: null,
};

Scoring.propTypes = {
    game: PropTypes.object.isRequired,
    player: PropTypes.object,
    setSubmit: PropTypes.func.isRequired,
    scale: PropTypes.number.isRequired,
    state: PropTypes.object.isRequired,
    zoom: PropTypes.func.isRequired,
};

export default Scoring;
