import React from 'react';
import useTranslate from 'hooks/useTranslate';

const Footer = () => {
    const t = useTranslate();

    return (
        <div className="footer">
            <span>{t('footer.ravensburger')}</span>
            <span>{t('footer.gameIdea')}: Dirk Baumann, Thomas Odenhoven, Matthias Schmitt</span>
            <span>{t('footer.onlineVersion')}: Martin Denk</span>
            <span>{t('footer.flags')}: www.countryflags.com</span>
        </div>
    );
};

export default Footer;
