import actions from '../actions';

export default (game) => (
    game.actions.reduce((result, action) => {
        if (actions[action.type].isEndGameAction) {
            return result;
        }

        return actions[action.type].prepareAndPerform(game, action, result);
    }, null)
);
