import localStorage from 'localStorage';

export const AUTH_TYPE_NONE = 'krazy-wordz-token-none';
export const AUTH_TYPE_PLAYER = 'krazy-wordz-token-player';

// In CARE-1141, we migrated from cookies to localStorage. In order to prevent everyone from being
// logged out of everything, we did a soft migration. Remove the cookie references in a couple of
// weeks (29.10.2020).
export const getToken = (authType) => (
    localStorage.read(authType)
);

export const setToken = (authType, token) => (
    localStorage.write(authType, token)
);

export const deleteToken = (authType) => (
    localStorage.remove(authType)
);
