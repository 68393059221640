import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Category = (props) => (
    <div
        className={classnames(
            'ui-category',
            { 'ui-category--clickable': !!props.onClick }
        )}
        onClick={props.onClick}
        style={props.style}
    >
        <div
            className={classnames(
                'ui-category__card',
                { 'ui-category__card--flipped': props.flipped }
            )}
        >
            <div className="ui-category__front">
                {props.name}
            </div>

            <div className="ui-category__back" />
        </div>
    </div>
);

Category.defaultProps = {
    flipped: false,
    onClick: null,
    style: null,
};

Category.propTypes = {
    flipped: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    style: PropTypes.object,
};

export default Category;
