import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Sortable from 'Sortable';

const Word = (props) => (
    <Sortable
        className={classnames(
            'ui-word',
            {
                'ui-word--small': props.small,
                'ui-word--static': !props.onChange,
            }
        )}
        disabled={props.disabled || !props.onChange}
        group="tiles"
        margin={props.small ? 0 : 1}
        name={props.name}
        onChange={props.onChange}
        scale={props.scale}
        style={{ borderColor: props.color }}
    >
        {props.children}
    </Sortable>
);

Word.defaultProps = {
    disabled: false,
    name: null,
    onChange: null,
    scale: null,
    small: false,
};

Word.propTypes = {
    children: PropTypes.node.isRequired,
    color: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    scale: PropTypes.number,
    small: PropTypes.bool,
};

export default Word;
