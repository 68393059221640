import { useMutation } from 'apollo-augmented-hooks';

const mutation = `
    mutation becomePlayer($id: ID!) {
        becomePlayer(id: $id) {
            token
            player {
                id
                connected
                game {
                    id
                }
            }
        }
    }
`;

export default () => {
    const mutate = useMutation(mutation);

    return (input) => (
        mutate({
            input,
            modifiers: [{
                fields: {
                    player: ({ item, toReference }) => (
                        toReference(item.player)
                    ),
                },
            }],
        })
    );
};
