import SETUP from './actions/SETUP';
import PREPARE_ROUND from './actions/PREPARE_ROUND';
import SUBMIT_WORD from './actions/SUBMIT_WORD';
import GUESS_WORDS from './actions/GUESS_WORDS';
import SHOW_CARD from './actions/SHOW_CARD';
import PROCEED_SCORING from './actions/PROCEED_SCORING';
import END_GAME from './actions/END_GAME';

export default {
    SETUP,
    PREPARE_ROUND,
    SUBMIT_WORD,
    GUESS_WORDS,
    SHOW_CARD,
    PROCEED_SCORING,
    END_GAME,
    0: SETUP,
    1: PREPARE_ROUND,
    2: SUBMIT_WORD,
    3: GUESS_WORDS,
    4: SHOW_CARD,
    5: PROCEED_SCORING,
    6: END_GAME,
};
