import { useMutation } from 'apollo-augmented-hooks';

const mutation = `
    mutation undoLastAction {
        undoLastAction {
            id
            status
            actions {
                id
                type
                payload
                randomSeed
                createdAt
                player {
                    id
                }
            }
        }
    }
`;

export default () => {
    const mutate = useMutation(mutation);

    return (input) => (
        mutate({ input })
    );
};
