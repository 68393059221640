import React from 'react';
import PropTypes from 'prop-types';
import Language from 'atoms/Language';

const LanguagePicker = (props) => {
    const onChangeHandler = (language) => () => {
        props.onChange(language);
    };

    return (
        <div className="ui-language-picker">
            {props.languages.map((language) => (
                <Language
                    key={language}
                    language={language}
                    onClick={onChangeHandler(language)}
                    selected={props.value === language}
                />
            ))}
        </div>
    );
};

LanguagePicker.defaultProps = {
    value: null,
};

LanguagePicker.propTypes = {
    languages: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

export default LanguagePicker;
