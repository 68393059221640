import React from 'react';
import PropTypes from 'prop-types';
import { arrayToggle } from 'helpers/array';

const CheckboxGroup = (props) => {
    const onChangeHandler = (value) => () => {
        props.onChange(arrayToggle(props.value, value));
    };

    return (
        <div className="ui-checkbox-group">
            {props.items.map((item) => (
                <div
                    key={item.value}
                    className="ui-checkbox-group__item"
                >
                    <label className="ui-checkbox-group__check">
                        <input
                            checked={props.value.includes(item.value)}
                            className="ui-checkbox-group__input"
                            onChange={onChangeHandler(item.value)}
                            type="checkbox"
                        />

                        <span className="ui-checkbox-group__box">
                            <i className="fas fa-check" />
                        </span>

                        <span className="ui-checkbox-group__label">
                            {item.label}
                        </span>
                    </label>

                    <div>
                        {item.action}
                    </div>
                </div>
            ))}
        </div>
    );
};

CheckboxGroup.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        action: PropTypes.node,
        label: PropTypes.node.isRequired,
        value: PropTypes.string.isRequired,
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CheckboxGroup;
