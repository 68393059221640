import { useSubscription } from 'apollo-augmented-hooks';

const subscription = `
    subscription playerConnectionChanged {
        playerConnectionChanged {
            id
            connected
        }
    }
`;

export default () => (
    useSubscription(subscription)
);
