import { useMutation } from 'apollo-augmented-hooks';
import moment from 'moment';

const mutation = `
    mutation pushActions($input: PushActionsInput!) {
        pushActions(input: $input) {
            id
            status
            actions {
                id
                type
                payload
                randomSeed
                createdAt
                player {
                    id
                }
            }
        }
    }
`;

export default () => {
    const mutate = useMutation(mutation);

    return (input, actions, player) => (
        mutate({
            input,
            optimisticResponse: {
                __typename: 'Game',
                status: 'LIVE',
                actions: [...actions, ...input.actions].map((action) => ({
                    __typename: 'Action',
                    id: action.id,
                    type: action.type,
                    payload: action.payload,
                    randomSeed: action.randomSeed || null,
                    createdAt: action.createdAt || moment().toISOString(),
                    player: action.player || player,
                })),
            },
        })
    );
};
