import moment from 'moment';
import Action from '../classes/Action';
import states from '../states';

export default class extends Action {
    static get id() {
        return 3;
    }

    static isValid({ state, player, payload }) {
        if (state.state !== states.GUESSING.id) {
            return false;
        }

        return Object.keys(payload.guesses).length === state.players.filter(({ id }) => id !== player.id).length;
    }

    static perform({ state, player, payload, createdAt }) {
        const playerIndex = state.players.findIndex(({ id }) => id === player.id);
        const players = [
            ...state.players.slice(0, playerIndex),
            {
                ...state.players[playerIndex],
                guesses: payload.guesses,
                shownSolution: false,
                shownGuesses: [],
                guessedAt: createdAt,
            },
            ...state.players.slice(playerIndex + 1),
        ];
        const allPlayersReady = players.every(({ guesses }) => Object.keys(guesses).length > 0);

        return {
            ...state,
            game: {
                ...state.game,
                scoringOrder: allPlayersReady
                    ? [...players].sort((a, b) => moment(a.submittedAt).diff(b.submittedAt)).map(({ id }) => id)
                    : undefined,
            },
            players,
            state: allPlayersReady ? states.SCORING.id : states.GUESSING.id,
        };
    }
}
